import { useCallback } from 'react'

import {
  LSCurrentUserAccessToken,
  LSCurrentUserShopId,
  sdk
} from '../../config'
import { User } from '../../models/User'
import { useLazyGetUsersWithPermissionQuery } from '../../redux/api'
import { GetUsersWithPermissionActEnum, UserProfile } from '../../sdk'
import { handleSdkError } from '../../utils'
const getShopProfile = sdk.getShopProfile.bind(sdk)

export const useLoginWithToken = (
  setCurrentUser: React.Dispatch<React.SetStateAction<User | undefined>>,
  setUserShopId: React.Dispatch<React.SetStateAction<string | undefined>>,
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
) => {
  const [triggerGetPermission] = useLazyGetUsersWithPermissionQuery()
  const getPermissions = useCallback(
    async (bearToken: string, userShopId: string, user: UserProfile) => {
      const permissions = new Set<GetUsersWithPermissionActEnum>()
      if (userShopId) {
        const adminUsers = await triggerGetPermission({
          authorization: bearToken,
          shop: userShopId,
          act: GetUsersWithPermissionActEnum.AccessAdminReports
        }).unwrap()
        if (adminUsers.map((u) => u.id!).includes(user.id!)) {
          permissions.add(GetUsersWithPermissionActEnum.AccessAdminReports)
        }
        const timeClockUsers = await triggerGetPermission({
          authorization: bearToken,
          shop: userShopId,
          act: GetUsersWithPermissionActEnum.ManageTimeClock
        }).unwrap()
        if (timeClockUsers.map((u) => u.id!).includes(user.id!)) {
          permissions.add(GetUsersWithPermissionActEnum.ManageTimeClock)
        }
      }
      return permissions
    },
    [triggerGetPermission]
  )
  const loginWithToken = useCallback(
    async (
      accessToken: string,
      setLoading: React.Dispatch<React.SetStateAction<boolean>>,
      existingUserProfile?: UserProfile
    ) => {
      try {
        setLoading(true)
        const bearToken = `Bearer ${accessToken}`
        localStorage.setItem(LSCurrentUserAccessToken, accessToken)
        const userProfile =
          existingUserProfile ||
          (await sdk.getMyProfile({
            authorization: bearToken
          }))

        const roles = await sdk.getMyRoles({
          authorization: bearToken
        })
        let permissions
        let shop
        if (localStorage.getItem(LSCurrentUserShopId)) {
          const shopId = localStorage.getItem(LSCurrentUserShopId)!
          setUserShopId(shopId)
          permissions = await getPermissions(bearToken, shopId!, userProfile)
          shop = await getShopProfile({
            authorization: bearToken,
            id: shopId
          })
        } else {
          const shopId = roles[0]?.shop
          if (shopId) {
            localStorage.setItem(LSCurrentUserShopId, shopId)
            setUserShopId(roles[0].shop!)
            permissions = await getPermissions(bearToken, shopId, userProfile)
            shop = await getShopProfile({
              authorization: bearToken,
              id: shopId
            })
          }
        }
        const user: User = {
          ...userProfile,
          id: userProfile.id!,
          email: userProfile.email!,
          name: userProfile.name!,
          accessToken: `Bearer ${accessToken}`,
          roles,
          permissions: permissions || new Set(),
          shop
        }
        setCurrentUser(user)
        return user
      } catch (e) {
        const message = (await handleSdkError(e)).message
        setErrorMessage(message)
      } finally {
        setLoading(false)
      }
    },
    [getPermissions, setCurrentUser, setErrorMessage, setUserShopId]
  )
  return loginWithToken
}
