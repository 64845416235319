import { useContext } from 'react'

import { AuthContext } from '../../providers'
import { loggingAssert } from '../../utils/handleError/loggingAssert'

const useAccessTokenAndShopId: () => {
  accessToken: string
  shopId: string
  userId: string
} = () => {
  const { currentUser, userShopId } = useContext(AuthContext)
  const accessToken = loggingAssert(
    'Access token not found',
    currentUser?.accessToken
  )
  const shopId = loggingAssert('Shop ID not found', userShopId)
  const userId = loggingAssert('User ID not found', currentUser?.id)

  return {
    accessToken,
    shopId,
    userId
  }
}

export default useAccessTokenAndShopId
