import { Close } from '@mui/icons-material'
import { Alert, Box, IconButton } from '@mui/material'
import { SerializedError } from '@reduxjs/toolkit'
import React, { FC, useEffect, useState } from 'react'

import { LoadingBackdrop } from './LoadingBackdrop'

interface Props {
  loading?: boolean
  errorMessage?: string | SerializedError
  clearError?: () => void
  children?: React.ReactNode
}

export const ErrorMessageWrapper: FC<Props> = ({
  children,
  loading,
  errorMessage
}) => {
  const [error, setError] = useState<string | SerializedError | undefined>()
  useEffect(() => setError(errorMessage), [errorMessage])
  return (
    <Box sx={{ position: 'relative' }}>
      {error && (
        <Alert
          action={
            <IconButton size="small" onClick={() => setError('')}>
              <Close fontSize="inherit" />
            </IconButton>
          }
          severity="error">
          {typeof error === 'string' ? error : error.message}
        </Alert>
      )}
      {loading && <LoadingBackdrop />}
      {children}
    </Box>
  )
}
