import React, {
  createContext,
  FC,
  useCallback,
  useEffect,
  useState
} from 'react'

import { ErrorMessageWrapper } from '../../components/SharedUI'
import { NO_MATCHES_STATUS } from '../../config'
import { sdk } from '../../config/sdk'
import useAccessTokenAndShopId from '../../hooks/useAccessTokenAndShopId/useAccessTokenAndShopId'
import {
  useGetVehicleDetailQuery,
  useGetVehicleImagesQuery
} from '../../redux/api'
import {
  GetVehicleInfoFromLicensePlateRegisteredCountryEnum,
  GetVehicleInfoFromLicensePlateRegisteredStateEnum,
  VehicleImageEntry,
  VehicleInformation,
  VehicleInformationRegisteredStateEnum
} from '../../sdk'
import { handleSdkError } from '../../utils'

interface VehicleDetailContextValues {
  vehicleId: string | undefined
  oldVehicleInfo: VehicleInformation | undefined
  newVehicleInfo: VehicleInformation | undefined
  setNewVehicleInfo: (vehicleInfo: VehicleInformation) => void
  state:
    | GetVehicleInfoFromLicensePlateRegisteredStateEnum
    | VehicleInformationRegisteredStateEnum
    | undefined
  licensePlate: string
  vin: string
  setState: (
    data:
      | GetVehicleInfoFromLicensePlateRegisteredStateEnum
      | VehicleInformationRegisteredStateEnum
      | undefined
  ) => void
  setLicensePlate: (data: string) => void
  setVin: (data: string) => void

  vinMatchLP: boolean
  foundVin: boolean
  foundLicense: boolean
  setVinMatchLP: (searched: boolean) => void
  setFoundLicense: (searched: boolean) => void
  setFoundVin: (searched: boolean) => void
  vinSearched: boolean
  licenseSearched: boolean
  vinCanBeSubmitted: boolean
  licenseCanBeSubmitted: boolean
  vehicleIsCustom: boolean
  vehicleImages: VehicleImageEntry[] | undefined
}

export const VehicleDetailContext = createContext<VehicleDetailContextValues>({
  vehicleId: undefined,
  oldVehicleInfo: undefined,
  newVehicleInfo: undefined,
  setNewVehicleInfo: () => {},
  state: undefined,
  licensePlate: '',
  vin: '',
  setState: () => {},
  setLicensePlate: () => {},
  setVin: () => {},

  vinMatchLP: true,
  foundVin: true,
  foundLicense: true,
  setVinMatchLP: () => {},
  setFoundLicense: () => {},
  setFoundVin: () => {},
  vehicleIsCustom: false,
  vinSearched: true,
  licenseSearched: true,
  vinCanBeSubmitted: true,
  licenseCanBeSubmitted: true,
  vehicleImages: undefined
})

interface Props {
  vehicleId: string
  children?: React.ReactNode
}

export const VehicleDetailProvider: FC<Props> = ({ vehicleId, children }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [newVehicleInfo, setNewVehicleInfo] = useState<
    VehicleInformation | undefined
  >()
  const [vinMatchLP, setVinMatchLP] = useState(true)
  const [foundLicense, setFoundLicense] = useState(true)
  const [foundVin, setFoundVin] = useState(true)
  const [licensePlate, setLicensePlate] = useState('')
  const [vin, setVin] = useState('')
  const [state, setState] = useState<
    | GetVehicleInfoFromLicensePlateRegisteredStateEnum
    | VehicleInformationRegisteredStateEnum
    | undefined
  >()
  const { accessToken, shopId } = useAccessTokenAndShopId()
  const {
    data: vehicles,
    error: getVehicleError,
    isLoading
  } = useGetVehicleDetailQuery({
    authorization: accessToken,
    shop: shopId,
    vehicle: [vehicleId]
  })
  const {
    data: vehicleImages,
    error: getVehicleImagesError,
    isLoading: isLoadingVehicleImages
  } = useGetVehicleImagesQuery({
    authorization: accessToken,
    shop: shopId,
    vehicle: vehicleId
  })
  const oldVehicleInfo = vehicles && vehicles[0]?.info
  useEffect(() => {
    if (oldVehicleInfo) {
      setNewVehicleInfo(oldVehicleInfo)
    }
  }, [oldVehicleInfo])
  useEffect(() => {
    setLicensePlate(newVehicleInfo?.licensePlate || '')
  }, [newVehicleInfo?.licensePlate])
  useEffect(() => {
    setVin(newVehicleInfo?.vin || '')
  }, [newVehicleInfo?.vin])
  useEffect(() => {
    setState(newVehicleInfo?.registeredState)
  }, [newVehicleInfo?.registeredState])
  const handleSearchVinAndLicense = useCallback(
    async (
      vinToSearch: string,
      licenseToSearch: string,
      stateToSearch: string
    ) => {
      try {
        setLoading(true)
        if (vinToSearch) {
          const response = await sdk.getVehicleInfoFromVinRaw({
            authorization: accessToken,
            shop: shopId,
            vin: vinToSearch
          })
          if (response!.raw.status === NO_MATCHES_STATUS) {
            setFoundVin(false)
          }
        }
        if (licenseToSearch && stateToSearch) {
          const response = await sdk.getVehicleInfoFromLicensePlateRaw({
            authorization: accessToken,
            registeredCountry:
              GetVehicleInfoFromLicensePlateRegisteredCountryEnum.Usa,
            registeredState:
              stateToSearch as GetVehicleInfoFromLicensePlateRegisteredStateEnum,
            licensePlate: licenseToSearch
          })
          if (response.raw.status === NO_MATCHES_STATUS) {
            setFoundLicense(false)
          }
        }
      } catch (error) {
        const message = (await handleSdkError(error)).message
        setError(message)
      } finally {
        setLoading(false)
      }
    },
    [accessToken, shopId]
  )
  useEffect(() => {
    handleSearchVinAndLicense(
      oldVehicleInfo?.vin || '',
      oldVehicleInfo?.licensePlate || '',
      oldVehicleInfo?.registeredState || ''
    )
  }, [handleSearchVinAndLicense, oldVehicleInfo])

  const licenseSearched =
    licensePlate === newVehicleInfo?.licensePlate &&
    state === newVehicleInfo?.registeredState
  const vinSearched = vin === newVehicleInfo?.vin
  const vehicleIsCustom = !!(
    oldVehicleInfo &&
    (oldVehicleInfo.make?.id === undefined ||
      oldVehicleInfo.model?.id === undefined)
  )
  const vinCanBeSubmitted = !vin || vinSearched
  const licenseCanBeSubmitted = !licensePlate || licenseSearched

  return (
    <VehicleDetailContext.Provider
      value={{
        vehicleId,
        oldVehicleInfo,
        newVehicleInfo,
        setNewVehicleInfo,
        licensePlate,
        state,
        vin,
        setLicensePlate,
        setState,
        setVin,
        vinMatchLP,
        setVinMatchLP,
        vehicleIsCustom,
        setFoundLicense,
        setFoundVin,
        foundLicense,
        foundVin,
        vinSearched,
        licenseSearched,
        vinCanBeSubmitted,
        licenseCanBeSubmitted,
        vehicleImages
      }}>
      <ErrorMessageWrapper
        loading={isLoading || loading || isLoadingVehicleImages}
        errorMessage={error || getVehicleError || getVehicleImagesError}>
        {children}
      </ErrorMessageWrapper>
    </VehicleDetailContext.Provider>
  )
}
