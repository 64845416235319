import Lottie from 'lottie-react'
import styled from 'styled-components'

import animation from '../../assets/animations/loading-animation.json'

const FullWidthHeightContainer = styled.div`
  width: 100%;
`

const CenteredInViewPort = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30%;
`

const LoadingAnimation = () => {
  return (
    <FullWidthHeightContainer>
      <CenteredInViewPort>
        <Lottie
          animationData={animation}
          loop={true}
          style={{ height: '200px' }}
        />
      </CenteredInViewPort>
    </FullWidthHeightContainer>
  )
}

export default LoadingAnimation
