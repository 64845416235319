import { sdk } from '../../config'
import {
  CreateTimeClockRequest,
  ListTimeClockEntriesRequest,
  ListTimeClockHistoryEntriesRequest,
  TimeClockEntry,
  UpdateTimeClockRequest
} from '../../sdk'
import { stripQuotes } from '../../utils'
import { api } from '.'
const listTimeClockEntries = sdk.listTimeClockEntries.bind(sdk)
const listTimeClockHistoryEntries = sdk.listTimeClockHistoryEntries.bind(sdk)
const createTimeClock = sdk.createTimeClock.bind(sdk)
const updateTimeClock = sdk.updateTimeClock.bind(sdk)

const timeClockApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listTimeClockEntries: builder.query<
      TimeClockEntry[],
      ListTimeClockEntriesRequest
    >({
      query: (req) => () => listTimeClockEntries(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'TimeClock' as const,
                id: entry.id!
              })),
              'TimeClock'
            ]
          : ['TimeClock']
    }),
    listTimeClockHistoryEntries: builder.query<
      TimeClockEntry[],
      ListTimeClockHistoryEntriesRequest
    >({
      query: (req) => () => listTimeClockHistoryEntries(req),
      providesTags: ['TimeClockHistory']
    }),
    createTimeClock: builder.mutation<string, CreateTimeClockRequest>({
      query: (req) => () => createTimeClock(req),
      transformResponse: (response: string) => stripQuotes(response),
      invalidatesTags: ['TimeClock']
    }),
    updateTimeClock: builder.mutation<void, UpdateTimeClockRequest>({
      query: (req) => () => updateTimeClock(req),
      invalidatesTags: ['TimeClock', 'TimeClockHistory']
    })
  }),

  overrideExisting: true
})

export const {
  useCreateTimeClockMutation,
  useUpdateTimeClockMutation,
  useListTimeClockEntriesQuery,
  useListTimeClockHistoryEntriesQuery
} = timeClockApi
