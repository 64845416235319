import { FirebaseApp } from 'firebase/app'
import { getAuth, onAuthStateChanged, Unsubscribe } from 'firebase/auth'
import { useEffect, useState } from 'react'

import { handleFirebaseError } from '../../utils'

interface FirebaseAuthHook {
  firebaseReady: boolean
  errorMessage: string
}

export const useFirebaseAuth = (firebaseApp: FirebaseApp): FirebaseAuthHook => {
  const [firebaseReady, setFirebaseReady] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    let unregisterAuthObserver: Unsubscribe

    const firebaseAuthInit = async () => {
      try {
        const auth = getAuth(firebaseApp)
        unregisterAuthObserver = onAuthStateChanged(auth, () => {
          if (!firebaseReady) {
            setFirebaseReady(true)
          }
        })
      } catch (error: unknown) {
        const message = handleFirebaseError(error, {})
        setErrorMessage(message)
        setFirebaseReady(false)
      }
    }

    firebaseAuthInit()

    return () => unregisterAuthObserver && unregisterAuthObserver()
  }, [firebaseApp, firebaseReady])

  return {
    firebaseReady,
    errorMessage
  }
}
