import RectangleIcon from '@mui/icons-material/Rectangle'
import { IconButton, ListItemButton, ListItemIcon, Menu } from '@mui/material'
import React from 'react'
export const APPOINTMENT_COLORS = [
  '5EA7FEFF',
  'FF7A92FF',
  'D2B2FFFF',
  'FFDBA5FF',
  'CCF0D4FF',
  '88DED9FF',
  'FF97E2FF',
  'FFC0B1FF'
]
export const APPOINTMENT_COLORS_DARK = [
  '0D7AFBFF',
  'FF4D6DFF',
  '7E3ADFFF',
  'FB9C0DFF',
  '3EB157FF',
  '8879B3FF',
  '26C0B7FF',
  'B5248CFF'
]

export const AppointmentColorSelect = ({
  color,
  setColor
}: {
  color: string
  setColor: (c: string) => void
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <IconButton onClick={handleClick}>
        <RectangleIcon sx={{ color: '#' + color }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ zIndex: '1600' }}>
        {APPOINTMENT_COLORS_DARK.map((c) => (
          <ListItemButton
            key={c}
            selected={color === c}
            onClick={(event) => {
              event.stopPropagation()
              setAnchorEl(null)
              setColor(c)
            }}>
            <ListItemIcon>
              <RectangleIcon sx={{ color: '#' + c }} />
            </ListItemIcon>
          </ListItemButton>
        ))}
      </Menu>
    </>
  )
}
