import { SerializedError } from '@reduxjs/toolkit'
import { useMemo } from 'react'

import { useListRepairOrderStatusesForShopQuery } from '../../redux/api'
import { RepairOrderStatusEntry } from '../../sdk'
import useAccessTokenAndShopId from '../useAccessTokenAndShopId/useAccessTokenAndShopId'

type Hook = () => {
  data: RepairOrderStatusEntry[]
  isLoading: boolean
  error: string | SerializedError | undefined
}

const useFilteredRoStatuses: Hook = () => {
  const { accessToken, shopId } = useAccessTokenAndShopId()
  const {
    data: statuses,
    isLoading,
    error
  } = useListRepairOrderStatusesForShopQuery({
    authorization: accessToken,
    shop: shopId
  })

  const filteredStatuses: RepairOrderStatusEntry[] = useMemo(() => {
    if (!statuses) {
      return []
    }
    const replaced = new Set<string>()
    statuses.forEach((entry) => {
      if (entry.replacing) {
        replaced.add(entry.replacing)
      }
    })
    return statuses
      .filter(({ id, hide }) => !replaced.has(id!) && !hide)
      .map((entry) => ({
        ...entry,
        id: replaced.has(entry.replacing!) ? entry.replacing! : entry.id!
      }))
      .sort((a, b) => a.rank! - b.rank!)
  }, [statuses])

  return { data: filteredStatuses, isLoading, error }
}

export default useFilteredRoStatuses
