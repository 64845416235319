import React, { createContext, FC, useState } from 'react'

type NavVisibilityContextValues = {
  // Have the navigation drawer remain open even if it would otherwise be hidden
  // due to responsive CSS
  stayOpen: boolean
  setStayOpen: (value: boolean) => void
  iconsOnly: boolean
  setIconsOnly: (value: boolean) => void
}

export const NavVisibilityContext = createContext<NavVisibilityContextValues>({
  stayOpen: false,
  setStayOpen: () => {},
  iconsOnly: false,
  setIconsOnly: () => {}
})

interface Props {
  children?: React.ReactNode
}

export const NavVisibilityProvider: FC<Props> = ({ children }) => {
  const [stayOpen, setStayOpen] = useState(false)
  const [iconsOnly, setIconsOnly] = useState(true)

  return (
    <NavVisibilityContext.Provider
      value={{ stayOpen, setStayOpen, iconsOnly, setIconsOnly }}>
      {children}
    </NavVisibilityContext.Provider>
  )
}
