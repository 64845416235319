import { sdk } from '../../config'
import {
  CreatePushNotificationRequest,
  CreatePushSubscriptionRequest,
  GetNumUnreadNotificationsRequest,
  GetUnreadNotificationsByModifiedEntityRequest,
  ListNotificationsRequest,
  MarkAllNotificationsAsReadRequest,
  MarkNotificationAsReadRequest,
  MarkNotificationAsUnreadRequest,
  NotificationEntry
} from '../../sdk'
import { api } from '.'
const listNotifications = sdk.listNotifications.bind(sdk)
const getNumUnreadNotifications = sdk.getNumUnreadNotifications.bind(sdk)
const createPushNotification = sdk.createPushNotification.bind(sdk)
const createPushSubscription = sdk.createPushSubscription.bind(sdk)
const markNotificationAsRead = sdk.markNotificationAsRead.bind(sdk)
const markNotificationAsUnread = sdk.markNotificationAsUnread.bind(sdk)
const markAllNotificationsAsRead = sdk.markAllNotificationsAsRead.bind(sdk)
const getUnreadNotificationsByModifiedEntity =
  sdk.getUnreadNotificationsByModifiedEntity.bind(sdk)

const notificationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listNotifications: builder.query<
      NotificationEntry[],
      ListNotificationsRequest
    >({
      query: (req) => () => listNotifications(req),
      providesTags: ['Notification']
    }),
    getNumUnreadNotifications: builder.query<
      number,
      GetNumUnreadNotificationsRequest
    >({
      query: (req) => () => getNumUnreadNotifications(req),
      providesTags: ['Notification']
    }),
    getUnreadNotificationsByModifiedEntity: builder.query<
      NotificationEntry[],
      GetUnreadNotificationsByModifiedEntityRequest
    >({
      query: (req) => () => getUnreadNotificationsByModifiedEntity(req),
      providesTags: ['Notification']
    }),
    createPushNotification: builder.mutation<
      Array<NotificationEntry>,
      CreatePushNotificationRequest
    >({
      query: (req) => () => createPushNotification(req),
      invalidatesTags: ['Notification']
    }),
    markNotificationAsRead: builder.mutation<
      void,
      MarkNotificationAsReadRequest
    >({
      query: (req) => () => markNotificationAsRead(req),
      invalidatesTags: ['Notification']
    }),
    markNotificationAsUnread: builder.mutation<
      void,
      MarkNotificationAsUnreadRequest
    >({
      query: (req) => () => markNotificationAsUnread(req),
      invalidatesTags: ['Notification']
    }),
    markAllNotificationsAsRead: builder.mutation<
      void,
      MarkAllNotificationsAsReadRequest
    >({
      query: (req) => () => markAllNotificationsAsRead(req),
      invalidatesTags: ['Notification']
    }),
    createPushSubscription: builder.mutation<
      void,
      CreatePushSubscriptionRequest
    >({
      query: (req) => () => createPushSubscription(req)
    })
  }),

  overrideExisting: true
})

export const {
  useCreatePushNotificationMutation,
  useListNotificationsQuery,
  useCreatePushSubscriptionMutation,
  useMarkNotificationAsReadMutation,
  useMarkNotificationAsUnreadMutation,
  useMarkAllNotificationsAsReadMutation,
  useGetNumUnreadNotificationsQuery,
  useGetUnreadNotificationsByModifiedEntityQuery
} = notificationApi
