import { handleGenericError } from './handleGenericError'
import { sendBugReport } from './sendBugReport'

export const handleFirebaseError = (e: unknown, context: object) => {
  if (!(e instanceof Error)) {
    return handleGenericError(e)
  }

  if (e.name !== 'FirebaseError') {
    return handleGenericError(e)
  }
  if (e.message.includes('auth/user-not-found')) {
    sendBugReport(
      'warning',
      'User tried to sign in to Firebase, but the user name does not exist',
      `Context: ${JSON.stringify(context)}`
    )
    return 'User not found'
  }
  if (e.message.includes('auth/wrong-password')) {
    sendBugReport(
      'warning',
      'User tried to sign in to Firebase, but the password was incorrect',
      `Context: ${JSON.stringify(context)}`
    )
    return 'Incorrect password'
  }
  if (e.message.includes('auth/weak-password')) {
    sendBugReport(
      'warning',
      'User tried to create an account with a password that does not meet the complexity requirements',
      `Context: ${JSON.stringify(context)}`
    )
    return 'Password must be at least 6 characters long'
  }
  if (e.message.includes('auth/invalid-email')) {
    sendBugReport(
      'warning',
      'User submitted an invalid email address to a Firebase API',
      `Context: ${JSON.stringify(context)}`
    )
    return 'Invalid email address entered'
  }
  if (e.message.includes('auth/email-already-in-use')) {
    sendBugReport(
      'warning',
      'User submitted an email address already in use to a Firebase API',
      `Context: ${JSON.stringify(context)}`
    )
    return 'Email address entered is already in use'
  }
  return handleGenericError(e)
}
