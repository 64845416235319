import { VehicleInformation } from '../../sdk'

export const describeVehicle = (info: VehicleInformation | undefined) => {
  if (!info) {
    return 'No Vehicle'
  }
  const { year, make, model, subModel } = info
  const substrings: string[] = []
  if (year) {
    substrings.push(String(year))
  }
  if (make?.name) {
    substrings.push(make.name)
  }
  if (model?.name) {
    substrings.push(model.name)
  }
  if (subModel?.name) {
    substrings.push(subModel.name)
  }
  return substrings.join(' ')
}
export const describeVehicleYMM = (info: VehicleInformation | undefined) => {
  if (!info) {
    return 'No Vehicle'
  }
  const { year, make, model } = info
  const substrings: string[] = []
  if (year) {
    substrings.push(String(year))
  }
  if (make?.name) {
    substrings.push(make.name)
  }
  if (model?.name) {
    substrings.push(model.name)
  }
  return substrings.join(' ')
}
export const describeLicense = (info: VehicleInformation | undefined) => {
  if (!info) {
    return 'No Vehicle'
  }
  const { licensePlate, registeredState } = info
  if (!licensePlate) {
    return ''
  }
  return [licensePlate.toUpperCase(), registeredState].join(' ')
}
