import { sdk } from '../../config'
import {
  ApiResponse,
  Brand,
  BrowseServicesFromTaxonomyRequest,
  CannedJobCustomPartEntry,
  CannedJobEntry,
  CapitalOsAccount,
  CheckFeaturesForShopByApiKeyRequest,
  CheckFeaturesForShopRequest,
  CreateEmbeddedDashboardLinkRequest,
  CustomerPages,
  ExpenseAnalysisResponse,
  FluidApplication,
  GenerateInspectionCustomerNoteRequest,
  GenerateRepairOrderCustomerSummaryRequest,
  GetAccountRequest,
  GetCustomerPagesRequest,
  GetLaborMatrixUsersRequest,
  GetPartsMatrixUsersRequest,
  GetVehicleInfoFromLicensePlateWithVerificationIdRequest,
  IdName,
  InitiateAccountLoginRequest,
  InventoryPartEntry,
  JoinCustomerChatRequest,
  LaborApplication,
  ListFluidApplicationsForTaxonomyRequest,
  LookupPhoneNumberRequest,
  LookupServicesRequest,
  MaintenanceScheduleWithRecommendation,
  PartInvoiceAnalysisResponse,
  PartsTechPartTaxonomy,
  PartsTechPartTaxonomyFromJSON,
  RecommendMaintenanceScheduleOperationRequest,
  ReplyRequest,
  RewriteOperationRequest,
  SearchCannedJobsRequest,
  SearchCannedPartsRequest,
  SearchInventoryPartsRequest,
  SearchPartsTechBrandsRequest,
  SearchPartsTechSuppliersRequest,
  UploadScannedDocumentRequest,
  UploadScannedExpenseDocumentRequest,
  VehicleInformation
} from '../../sdk'
import { LaborMatrixUsers } from '../../sdk/models/LaborMatrixUsers'
import { PartsMatrixUsers } from '../../sdk/models/PartsMatrixUsers'
import { stripQuotes } from '../../utils'
import { api } from './'
const getLaborMatrixUsers = sdk.getLaborMatrixUsers.bind(sdk)
const getPartsMatrixUsers = sdk.getPartsMatrixUsers.bind(sdk)
const searchInventoryParts = sdk.searchInventoryParts.bind(sdk)
const searchPartsTechBrands = sdk.searchPartsTechBrands.bind(sdk)
const searchPartsTechSuppliers = sdk.searchPartsTechSuppliers.bind(sdk)
const searchCannedJobs = sdk.searchCannedJobs.bind(sdk)
const browseServicesFromTaxonomy = sdk.browseServicesFromTaxonomy.bind(sdk)
const lookupServices = sdk.lookupServices.bind(sdk)
const createEmbeddedDashboardLink = sdk.createEmbeddedDashboardLink.bind(sdk)
const listFluidApplicationsForTaxonomy =
  sdk.listFluidApplicationsForTaxonomy.bind(sdk)
const searchCannedParts = sdk.searchCannedParts.bind(sdk)

const checkFeaturesForShop = sdk.checkFeaturesForShop.bind(sdk)
const checkFeaturesForShopByApiKey = sdk.checkFeaturesForShopByApiKey.bind(sdk)

const generateRepairOrderCustomerSummary =
  sdk.generateRepairOrderCustomerSummary.bind(sdk)
const rewrite = sdk.rewrite.bind(sdk)
const reply = sdk.reply.bind(sdk)
const generateInspectionCustomerNote =
  sdk.generateInspectionCustomerNote.bind(sdk)
const uploadScannedDocument = sdk.uploadScannedDocument.bind(sdk)
const uploadScannedExpenseDocument = sdk.uploadScannedExpenseDocument.bind(sdk)
const recommendMaintenanceSchedule = sdk.recommendMaintenanceSchedule.bind(sdk)

const getCustomerPages = sdk.getCustomerPages.bind(sdk)

const getAccount = sdk.getAccount.bind(sdk)
const initiateAccountLogin = sdk.initiateAccountLogin.bind(sdk)

const joinCustomerChat = sdk.joinCustomerChat.bind(sdk)
const getVehicleInfoFromLicensePlateWithVerificationIdRaw =
  sdk.getVehicleInfoFromLicensePlateWithVerificationIdRaw.bind(sdk)

const lookupPhoneNumber = sdk.lookupPhoneNumber.bind(sdk)

export const getLocalPartTaxonomy = async () => {
  const response = await fetch('/cached_part_taxonomy.json', {
    headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
  })
  const json = await response.json()
  return PartsTechPartTaxonomyFromJSON(json)
}

// This api slice is for independent APIs that does not involve creating new tags or invalidating existing tags, such as APIs for searching triggered by input changes
const independentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getLaborMatrixUsers: builder.query<
      LaborMatrixUsers,
      GetLaborMatrixUsersRequest
    >({
      query: (req) => () => getLaborMatrixUsers(req)
    }),
    getPartsMatrixUsers: builder.query<
      PartsMatrixUsers,
      GetPartsMatrixUsersRequest
    >({
      query: (req) => () => getPartsMatrixUsers(req)
    }),
    searchPartsTechBrands: builder.query<Brand[], SearchPartsTechBrandsRequest>(
      {
        query: (req) => () => searchPartsTechBrands(req)
      }
    ),
    searchPartsTechSuppliers: builder.query<
      Array<IdName>,
      SearchPartsTechSuppliersRequest
    >({
      query: (req) => () => searchPartsTechSuppliers(req)
    }),
    searchInventoryParts: builder.query<
      InventoryPartEntry[],
      SearchInventoryPartsRequest
    >({
      query: (req) => () => searchInventoryParts(req)
    }),
    searchCannedParts: builder.query<
      CannedJobCustomPartEntry[],
      SearchCannedPartsRequest
    >({
      query: (req) => () => searchCannedParts(req)
    }),
    searchCannedJobs: builder.query<CannedJobEntry[], SearchCannedJobsRequest>({
      query: (req) => () => searchCannedJobs(req)
    }),
    lookupServices: builder.query<
      Array<LaborApplication>,
      LookupServicesRequest
    >({
      query: (req) => () => lookupServices(req)
    }),
    browseServicesFromTaxonomy: builder.query<
      Array<LaborApplication>,
      BrowseServicesFromTaxonomyRequest
    >({
      query: (req) => () => browseServicesFromTaxonomy(req)
    }),
    listFluidApplicationsForTaxonomy: builder.query<
      Array<FluidApplication>,
      ListFluidApplicationsForTaxonomyRequest
    >({
      query: (req) => () => listFluidApplicationsForTaxonomy(req)
    }),
    createEmbeddedDashboardLink: builder.mutation<
      string,
      CreateEmbeddedDashboardLinkRequest
    >({
      query: (req) => () => createEmbeddedDashboardLink(req)
    }),
    getLocalPartTaxonomy: builder.query<PartsTechPartTaxonomy, void>({
      query: () => () => getLocalPartTaxonomy()
    }),
    checkFeaturesForShop: builder.query<
      { [key: string]: boolean },
      CheckFeaturesForShopRequest
    >({
      query: (req) => () => checkFeaturesForShop(req)
    }),
    checkFeaturesForShopByApiKey: builder.query<
      { [key: string]: boolean },
      CheckFeaturesForShopByApiKeyRequest
    >({
      query: (req) => () => checkFeaturesForShopByApiKey(req)
    }),
    generateRepairOrderCustomerSummary: builder.mutation<
      string,
      GenerateRepairOrderCustomerSummaryRequest
    >({
      query: (req) => () => generateRepairOrderCustomerSummary(req)
    }),
    rewrite: builder.mutation<string, RewriteOperationRequest>({
      query: (req) => () => rewrite(req)
    }),
    reply: builder.mutation<string, ReplyRequest>({
      query: (req) => () => reply(req)
    }),
    generateInspectionCustomerNote: builder.mutation<
      string,
      GenerateInspectionCustomerNoteRequest
    >({
      query: (req) => () => generateInspectionCustomerNote(req),
      transformResponse: (response: string) => stripQuotes(response)
    }),
    recommendMaintenanceSchedule: builder.mutation<
      { [key: string]: MaintenanceScheduleWithRecommendation },
      RecommendMaintenanceScheduleOperationRequest
    >({
      query: (req) => () => recommendMaintenanceSchedule(req)
    }),
    getCustomerPages: builder.query<CustomerPages[], GetCustomerPagesRequest>({
      query: (req) => () => getCustomerPages(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'Customer' as const,
                id: entry.customer?.id
              })),
              'Customer'
            ]
          : ['Customer' as const]
    }),
    uploadScannedDocument: builder.query<
      PartInvoiceAnalysisResponse,
      UploadScannedDocumentRequest
    >({
      query: (req) => () => uploadScannedDocument(req)
    }),
    uploadScannedExpenseDocument: builder.query<
      ExpenseAnalysisResponse,
      UploadScannedExpenseDocumentRequest
    >({
      query: (req) => () => uploadScannedExpenseDocument(req)
    }),
    getAccount: builder.query<CapitalOsAccount, GetAccountRequest>({
      query: (req) => () => getAccount(req)
    }),
    initiateAccountLogin: builder.mutation<string, InitiateAccountLoginRequest>(
      {
        query: (req) => () => initiateAccountLogin(req),
        transformResponse: (response: string) => stripQuotes(response)
      }
    ),
    joinCustomerChat: builder.query<void, JoinCustomerChatRequest>({
      query: (req) => () => joinCustomerChat(req)
    }),
    getVehicleInfoFromLicensePlateWithVerificationIdRaw: builder.query<
      ApiResponse<VehicleInformation>,
      GetVehicleInfoFromLicensePlateWithVerificationIdRequest
    >({
      query: (req) => () =>
        getVehicleInfoFromLicensePlateWithVerificationIdRaw(req)
    }),
    lookupPhoneNumber: builder.query<void, LookupPhoneNumberRequest>({
      query: (req) => () => lookupPhoneNumber(req)
    })
  }),
  overrideExisting: false
})

export const {
  useLazyGetLaborMatrixUsersQuery,
  useLazySearchPartsTechBrandsQuery,
  useLazySearchInventoryPartsQuery,
  useLazySearchPartsTechSuppliersQuery,
  useLazyLookupRepairOrdersForPartOverridesQuery,
  useLazySearchCannedJobsQuery,
  useLazyLookupServicesQuery,
  useLazyBrowseServicesFromTaxonomyQuery,
  useBrowseServicesFromTaxonomyQuery,
  useLazyGetPartsMatrixUsersQuery,
  useCreateEmbeddedDashboardLinkMutation,
  useListFluidApplicationsForTaxonomyQuery,
  useLazyListFluidApplicationsForTaxonomyQuery,
  useGetLocalPartTaxonomyQuery,
  useCheckFeaturesForShopQuery,
  useCheckFeaturesForShopByApiKeyQuery,
  useLazySearchCannedPartsQuery,
  useGenerateRepairOrderCustomerSummaryMutation,
  useRewriteMutation,
  useReplyMutation,
  useGenerateInspectionCustomerNoteMutation,
  useLazyGetCustomerPagesQuery,
  useGetCustomerPagesQuery,
  useLazyUploadScannedDocumentQuery,
  useLazyUploadScannedExpenseDocumentQuery,
  useGetAccountQuery,
  useInitiateAccountLoginMutation,
  useLazyJoinCustomerChatQuery,
  useLazyGetVehicleInfoFromLicensePlateWithVerificationIdRawQuery,
  useLazyLookupPhoneNumberQuery,
  useRecommendMaintenanceScheduleMutation
} = independentApi
