import { styled, TextField, TextFieldProps } from '@mui/material'
import React from 'react'

import { ModifyObjectType } from '../../utils'

type Props = ModifyObjectType<TextFieldProps, {}>

// override MUI number input to remove up/down arrows
// https://stackoverflow.com/questions/50823182/material-ui-remove-up-down-arrow-dials-from-textview
const NumberInputWithoutArrows = styled(TextField)(() => ({
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none'
  },
  '& input[type=number]': {
    MozAppearance: 'textfield'
  }
}))

const TextFieldNoArrowNoScroll = React.forwardRef(
  ({ ...props }: Props, ref) => {
    if ('type' in props && props.type === 'number') {
      return (
        <NumberInputWithoutArrows
          {...props}
          onWheel={() => (document.activeElement as HTMLElement).blur()}
        />
      )
    }
    return <TextField inputRef={ref} {...props} />
  }
)

TextFieldNoArrowNoScroll.displayName = 'TextFieldNoArrowNoScroll'

export default TextFieldNoArrowNoScroll
