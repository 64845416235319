import { sdk } from '../../config'
import {
  ArchivePriceLevelRequest,
  AuthorizeShopForCarfaxRequest,
  AuthorizeShopForQuickVinRequest,
  CatalogLaborMatrixEntry,
  CreateCatalogLaborMatrixEntryRequest,
  CreateDiscountRequest,
  CreateInspectionEntryStatusRequest,
  CreateLaborMatrixEntryRequest,
  CreatePartsMatrixEntryRequest,
  CreatePriceLevelRequest,
  CreateProductivityStatusRequest,
  DeleteCatalogLaborMatrixEntryRequest,
  DeleteDiscountRequest,
  DeleteLaborMatrixEntryRequest,
  DeletePartsMatrixEntryRequest,
  GetCarfaxServicesRequest,
  GetCatalogLaborMatrixEntriesRequest,
  GetDiscountsForShopRequest,
  GetInspectionEntryStatusRequest,
  GetLaborMatrixEntriesRequest,
  GetPartsMatrixEntriesRequest,
  GetPriceLevelsRequest,
  GetProductivityStatusRequest,
  GetTaxesAndFeesForShopRequest,
  InspectionEntryStatusEntry,
  LaborMatrixEntry,
  PartsMatrixEntry,
  PriceLevelEntry,
  ProductivityStatusEntry,
  ServiceHistory,
  SetTaxesAndFeesRequest,
  TaxesAndFeesEntry,
  UpdateCatalogLaborMatrixEntryRequest,
  UpdateCatalogLaborMatrixRanksOperationRequest,
  UpdateDiscountRequest,
  UpdateInspectionEntryStatusRanksOperationRequest,
  UpdateInspectionEntryStatusRequest,
  UpdateLaborMatrixEntryRequest,
  UpdateLaborMatrixRanksOperationRequest,
  UpdateLaborMatrixUsersOperationRequest,
  UpdatePartsMatrixEntryRequest,
  UpdatePartsMatrixRanksOperationRequest,
  UpdatePartsMatrixUsersOperationRequest,
  UpdatePriceLevelRequest,
  UpdateProductivityStatusRequest
} from '../../sdk'
import { DiscountEntry } from '../../sdk/models/DiscountEntry'
import { stripQuotes } from '../../utils'
import { api } from './'

// parts and labor matrix, should invalidate related canned jobs and customers
const getPartsMatrixEntries = sdk.getPartsMatrixEntries.bind(sdk)
const getLaborMatrixEntries = sdk.getLaborMatrixEntries.bind(sdk)
const createLaborMatrixEntry = sdk.createLaborMatrixEntry.bind(sdk)
const updateLaborMatrixEntry = sdk.updateLaborMatrixEntry.bind(sdk)
const deleteLaborMatrixEntry = sdk.deleteLaborMatrixEntry.bind(sdk)
const createPartsMatrixEntry = sdk.createPartsMatrixEntry.bind(sdk)
const updatePartsMatrixEntry = sdk.updatePartsMatrixEntry.bind(sdk)
const deletePartsMatrixEntry = sdk.deletePartsMatrixEntry.bind(sdk)
const getPriceLevels = sdk.getPriceLevels.bind(sdk)
const createPriceLevel = sdk.createPriceLevel.bind(sdk)
const updatePriceLevel = sdk.updatePriceLevel.bind(sdk)
const archivePriceLevel = sdk.archivePriceLevel.bind(sdk)
const getCatalogLaborMatrixEntries = sdk.getCatalogLaborMatrixEntries.bind(sdk)
const createCatalogLaborMatrixEntry =
  sdk.createCatalogLaborMatrixEntry.bind(sdk)
const updateCatalogLaborMatrixEntry =
  sdk.updateCatalogLaborMatrixEntry.bind(sdk)
const deleteCatalogLaborMatrixEntry =
  sdk.deleteCatalogLaborMatrixEntry.bind(sdk)
const updateCatalogLaborMatrixRanks =
  sdk.updateCatalogLaborMatrixRanks.bind(sdk)

const getTaxesAndFeesForShop = sdk.getTaxesAndFeesForShop.bind(sdk)
const setTaxesAndFees = sdk.setTaxesAndFees.bind(sdk)
const updateLaborMatrixUsers = sdk.updateLaborMatrixUsers.bind(sdk)
const updatePartsMatrixUsers = sdk.updatePartsMatrixUsers.bind(sdk)
const createProductivityStatus = sdk.createProductivityStatus.bind(sdk)
const updateProductivityStatus = sdk.updateProductivityStatus.bind(sdk)
const getProductivityStatus = sdk.getProductivityStatus.bind(sdk)
const createInspectionEntryStatus = sdk.createInspectionEntryStatus.bind(sdk)
const updateInspectionEntryStatus = sdk.updateInspectionEntryStatus.bind(sdk)
const getInspectionEntryStatus = sdk.getInspectionEntryStatus.bind(sdk)
const updateInspectionEntryStatusRanks =
  sdk.updateInspectionEntryStatusRanks.bind(sdk)
const createDiscount = sdk.createDiscount.bind(sdk)
const updateDiscount = sdk.updateDiscount.bind(sdk)
const deleteDiscount = sdk.deleteDiscount.bind(sdk)
const getDiscountsForShop = sdk.getDiscountsForShop.bind(sdk)
const updatePartsMatrixRanks = sdk.updatePartsMatrixRanks.bind(sdk)
const updateLaborMatrixRanks = sdk.updateLaborMatrixRanks.bind(sdk)
const authorizeShopForCarfax = sdk.authorizeShopForCarfax.bind(sdk)
const getCarfaxServices = sdk.getCarfaxServices.bind(sdk)
const authorizeShopForQuickVin = sdk.authorizeShopForQuickVin.bind(sdk)

const shopMatrixApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPartsMatrixEntries: builder.query<
      PartsMatrixEntry[],
      GetPartsMatrixEntriesRequest
    >({
      query: (req) => () => getPartsMatrixEntries(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'Parts Matrix' as const,
                id: entry.id!
              })),
              'Parts Matrix'
            ]
          : ['Parts Matrix']
    }),
    createPartsMatrixEntry: builder.mutation<
      string,
      CreatePartsMatrixEntryRequest
    >({
      query: (req) => () => createPartsMatrixEntry(req),
      transformResponse: (response: string) => stripQuotes(response),
      invalidatesTags: ['Parts Matrix']
    }),
    updatePartsMatrixEntry: builder.mutation<
      string,
      UpdatePartsMatrixEntryRequest
    >({
      query: (req) => () => updatePartsMatrixEntry(req),
      transformResponse: (response: string) => stripQuotes(response),
      invalidatesTags: ['Parts Matrix', 'CannedJob', 'Customer']
    }),
    deletePartsMatrixEntry: builder.mutation<
      Date,
      DeletePartsMatrixEntryRequest
    >({
      query: (req) => () => deletePartsMatrixEntry(req),
      invalidatesTags: ['Parts Matrix']
    }),
    getLaborMatrixEntries: builder.query<
      LaborMatrixEntry[],
      GetLaborMatrixEntriesRequest
    >({
      query: (req) => () => getLaborMatrixEntries(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'Labor Matrix' as const,
                id: entry.id!
              })),
              'Labor Matrix'
            ]
          : ['Labor Matrix']
    }),
    createLaborMatrixEntry: builder.mutation<
      string,
      CreateLaborMatrixEntryRequest
    >({
      query: (req) => () => createLaborMatrixEntry(req),
      invalidatesTags: ['Labor Matrix']
    }),
    updateLaborMatrixEntry: builder.mutation<
      string,
      UpdateLaborMatrixEntryRequest
    >({
      query: (req) => () => updateLaborMatrixEntry(req),
      invalidatesTags: ['Labor Matrix', 'CannedJob', 'Customer']
    }),
    deleteLaborMatrixEntry: builder.mutation<
      Date,
      DeleteLaborMatrixEntryRequest
    >({
      query: (req) => () => deleteLaborMatrixEntry(req),
      invalidatesTags: ['Labor Matrix']
    }),
    getTaxesAndFees: builder.query<
      TaxesAndFeesEntry[],
      GetTaxesAndFeesForShopRequest
    >({
      query: (req) => () => getTaxesAndFeesForShop(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entity) => ({
                type: 'TaxesAndFees' as const,
                id: entity.id!
              })),
              'TaxesAndFees'
            ]
          : ['TaxesAndFees']
    }),
    setTaxesAndFees: builder.mutation<void, SetTaxesAndFeesRequest>({
      query: (req) => () => setTaxesAndFees(req),
      invalidatesTags: ['TaxesAndFees']
    }),
    updateLaborMatrixUsers: builder.mutation<
      void,
      UpdateLaborMatrixUsersOperationRequest
    >({
      query: (req) => () => updateLaborMatrixUsers(req),
      invalidatesTags: ['CannedJob', 'Customer']
    }),
    updatePartsMatrixUsers: builder.mutation<
      void,
      UpdatePartsMatrixUsersOperationRequest
    >({
      query: (req) => () => updatePartsMatrixUsers(req),
      invalidatesTags: ['CannedJob', 'Customer']
    }),
    createProductivityStatus: builder.mutation<
      string,
      CreateProductivityStatusRequest
    >({
      query: (req) => () => createProductivityStatus(req),
      transformResponse: (response: string) => stripQuotes(response),
      invalidatesTags: ['ProductivityStatus']
    }),
    updateProductivityStatus: builder.mutation<
      string,
      UpdateProductivityStatusRequest
    >({
      query: (req) => () => updateProductivityStatus(req),
      transformResponse: (response: string) => stripQuotes(response),
      invalidatesTags: ['ProductivityStatus']
    }),
    getProductivityStatus: builder.query<
      ProductivityStatusEntry[],
      GetProductivityStatusRequest
    >({
      query: (req) => () => getProductivityStatus(req),
      providesTags: ['ProductivityStatus']
    }),
    createInspectionEntryStatus: builder.mutation<
      string,
      CreateInspectionEntryStatusRequest
    >({
      query: (req) => () => createInspectionEntryStatus(req),
      transformResponse: (response: string) => stripQuotes(response),
      invalidatesTags: ['InspectionEntryStatus']
    }),
    updateInspectionEntryStatus: builder.mutation<
      string,
      UpdateInspectionEntryStatusRequest
    >({
      query: (req) => () => updateInspectionEntryStatus(req),
      transformResponse: (response: string) => stripQuotes(response),
      invalidatesTags: ['InspectionEntryStatus']
    }),
    getInspectionEntryStatus: builder.query<
      InspectionEntryStatusEntry[],
      GetInspectionEntryStatusRequest
    >({
      query: (req) => () => getInspectionEntryStatus(req),
      providesTags: ['InspectionEntryStatus']
    }),
    updateInspectionEntryStatusRanks: builder.mutation<
      void,
      UpdateInspectionEntryStatusRanksOperationRequest
    >({
      query: (req) => () => updateInspectionEntryStatusRanks(req),
      invalidatesTags: ['InspectionEntryStatus']
    }),
    getDiscountsForShop: builder.query<
      DiscountEntry[],
      GetDiscountsForShopRequest
    >({
      query: (req) => () => getDiscountsForShop(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'Discount' as const,
                id: entry.id!
              })),
              'Discount'
            ]
          : ['Discount']
    }),
    createDiscount: builder.mutation<string, CreateDiscountRequest>({
      query: (req) => () => createDiscount(req),
      transformResponse: (response: string) => stripQuotes(response),
      invalidatesTags: ['Discount']
    }),
    updateDiscount: builder.mutation<string, UpdateDiscountRequest>({
      query: (req) => () => updateDiscount(req),
      transformResponse: (response: string) => stripQuotes(response),
      invalidatesTags: ['Discount']
    }),
    deleteDiscount: builder.mutation<void, DeleteDiscountRequest>({
      query: (req) => () => deleteDiscount(req),
      invalidatesTags: ['Discount']
    }),
    getPriceLevels: builder.query<PriceLevelEntry[], GetPriceLevelsRequest>({
      query: (req) => () => getPriceLevels(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'Price Level' as const,
                id: entry.id!
              })),
              'Price Level'
            ]
          : ['Price Level']
    }),
    createPriceLevel: builder.mutation<string, CreatePriceLevelRequest>({
      query: (req) => () => createPriceLevel(req),
      invalidatesTags: ['Price Level']
    }),
    updatePriceLevel: builder.mutation<void, UpdatePriceLevelRequest>({
      query: (req) => () => updatePriceLevel(req),
      invalidatesTags: ['Price Level']
    }),
    archivePriceLevel: builder.mutation<void, ArchivePriceLevelRequest>({
      query: (req) => () => archivePriceLevel(req),
      invalidatesTags: ['Price Level']
    }),
    updatePartsMatrixRanks: builder.mutation<
      void,
      UpdatePartsMatrixRanksOperationRequest
    >({
      query: (req) => () => updatePartsMatrixRanks(req),
      invalidatesTags: ['Parts Matrix']
    }),
    updateLaborMatrixRanks: builder.mutation<
      void,
      UpdateLaborMatrixRanksOperationRequest
    >({
      query: (req) => () => updateLaborMatrixRanks(req),
      invalidatesTags: ['Labor Matrix']
    }),
    authorizeShopForCarfax: builder.mutation<
      void,
      AuthorizeShopForCarfaxRequest
    >({
      query: (req) => () => authorizeShopForCarfax(req)
    }),
    authorizeShopForQuickVin: builder.mutation<
      void,
      AuthorizeShopForQuickVinRequest
    >({
      query: (req) => () => authorizeShopForQuickVin(req),
      invalidatesTags: (res, err, req) => [{ type: 'Shop', id: req.body }]
    }),
    getCarfaxServices: builder.query<ServiceHistory, GetCarfaxServicesRequest>({
      query: (req) => () => getCarfaxServices(req)
    }),
    getCatalogLaborMatrixEntries: builder.query<
      CatalogLaborMatrixEntry[],
      GetCatalogLaborMatrixEntriesRequest
    >({
      query: (req) => () => getCatalogLaborMatrixEntries(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'CatalogLaborMatrix' as const,
                id: entry.id!
              })),
              'CatalogLaborMatrix'
            ]
          : ['CatalogLaborMatrix']
    }),
    createCatalogLaborMatrixEntry: builder.mutation<
      string,
      CreateCatalogLaborMatrixEntryRequest
    >({
      query: (req) => () => createCatalogLaborMatrixEntry(req),
      invalidatesTags: ['CatalogLaborMatrix']
    }),
    updateCatalogLaborMatrixEntry: builder.mutation<
      string,
      UpdateCatalogLaborMatrixEntryRequest
    >({
      query: (req) => () => updateCatalogLaborMatrixEntry(req),
      invalidatesTags: ['CatalogLaborMatrix']
    }),
    deleteCatalogLaborMatrixEntry: builder.mutation<
      Date,
      DeleteCatalogLaborMatrixEntryRequest
    >({
      query: (req) => () => deleteCatalogLaborMatrixEntry(req),
      invalidatesTags: ['CatalogLaborMatrix']
    }),
    updateCatalogLaborMatrixRanks: builder.mutation<
      void,
      UpdateCatalogLaborMatrixRanksOperationRequest
    >({
      query: (req) => () => updateCatalogLaborMatrixRanks(req),
      invalidatesTags: ['CatalogLaborMatrix']
    })
  }),
  overrideExisting: true
})

export const {
  useGetPartsMatrixEntriesQuery,
  useCreatePartsMatrixEntryMutation,
  useUpdatePartsMatrixEntryMutation,
  useDeletePartsMatrixEntryMutation,
  useGetLaborMatrixEntriesQuery,
  useCreateLaborMatrixEntryMutation,
  useUpdateLaborMatrixEntryMutation,
  useDeleteLaborMatrixEntryMutation,
  useGetTaxesAndFeesQuery,
  useSetTaxesAndFeesMutation,
  useUpdateLaborMatrixUsersMutation,
  useUpdatePartsMatrixUsersMutation,
  useGetProductivityStatusQuery,
  useCreateProductivityStatusMutation,
  useUpdateProductivityStatusMutation,
  useGetInspectionEntryStatusQuery,
  useCreateInspectionEntryStatusMutation,
  useUpdateInspectionEntryStatusMutation,
  useUpdateInspectionEntryStatusRanksMutation,
  useGetDiscountsForShopQuery,
  useCreateDiscountMutation,
  useUpdateDiscountMutation,
  useDeleteDiscountMutation,
  useGetPriceLevelsQuery,
  useCreatePriceLevelMutation,
  useUpdatePriceLevelMutation,
  useArchivePriceLevelMutation,
  useUpdatePartsMatrixRanksMutation,
  useUpdateLaborMatrixRanksMutation,
  useAuthorizeShopForCarfaxMutation,
  useGetCarfaxServicesQuery,
  useGetCatalogLaborMatrixEntriesQuery,
  useCreateCatalogLaborMatrixEntryMutation,
  useUpdateCatalogLaborMatrixEntryMutation,
  useDeleteCatalogLaborMatrixEntryMutation,
  useUpdateCatalogLaborMatrixRanksMutation,
  useAuthorizeShopForQuickVinMutation
} = shopMatrixApi
