import { sdk } from '../../config/sdk'
import { User } from '../../models'
import { describeLicense, describeVehicleYMM } from '../../utils'
import { formatName } from '../../utils/formatCustomerName/formatCustomerName'
import { loggingAssert } from '../../utils/handleError/loggingAssert'

export const CommandBarSetup = (currentUser: User, userShopId: string) => {
  window.CommandBar.boot(currentUser.id!, {
    name: currentUser.name!,
    email: currentUser.email!
  })

  window.CommandBar.setFormFactor({
    type: 'inline',
    rootElement: document.querySelector(
      '#commandbar-inline-root'
    ) as HTMLElement
  })

  window.CommandBar.addRecords('customers', [], {
    onInputChange: async (query: any) => {
      const results = await sdk.searchForCustomer({
        keyword: query,
        shop: loggingAssert('ShopId not set', userShopId),
        authorization: currentUser.accessToken! as string
      })
      const flattened = results.map((r) => {
        return {
          id: r.id,
          label: formatName(r.information),
          description: `Company: ${
            r.information!.companyName ? r.information!.companyName : 'N/A'
          }
              |    Phone: ${r.information!.phone ? r.information!.phone : 'N/A'}
              |    Email: ${
                r.information!.email ? r.information!.email : 'N/A'
              }`
        }
      })
      return flattened
    },
    labelKey: 'label',
    searchTabEnabled: true,
    descriptionKey: 'description',
    defaultIcon: '🧑'
  })
  window.CommandBar.addRecordAction('customers', {
    text: 'View Customer',
    name: 'view-customer',
    template: {
      type: 'link',
      value: '/customers/{{record.id}}',
      operation: 'router'
    }
  })

  window.CommandBar.addRecords('vehicles', [], {
    onInputChange: async (query: any) => {
      const results = await sdk.searchVehicleByKeyword({
        keyword: query,
        shop: loggingAssert('ShopId not set', userShopId),
        authorization: currentUser.accessToken! as string
      })
      const flattened = results.map((r) => {
        return {
          id: r.id,
          label: `${describeVehicleYMM(r.info!)} ${
            r.info?.name ? '(' + r.info?.name + ')' : ''
          }`,
          description: `Plate: ${describeLicense(r.info!)}    |    VIN: ${
            r.info!.vin
          }`
        }
      })
      return flattened
    },
    labelKey: 'label',
    searchTabEnabled: true,
    descriptionKey: 'description',
    defaultIcon: '🚗'
  })
  window.CommandBar.addRecordAction('vehicles', {
    text: 'View Vehicle',
    name: 'view-vehicle',
    template: {
      type: 'link',
      value: '/vehicles/{{record.id}}',
      operation: 'router'
    }
  })

  window.CommandBar.addRecords('orders', [], {
    onInputChange: async (query: any) => {
      const results = await sdk.searchRepairOrderByKeyword({
        keyword: query,
        shop: loggingAssert('ShopId not set', userShopId),
        authorization: currentUser.accessToken! as string,
        isArchived: false,
        isEstimate: false
      })
      const flattened = results.map((r) => {
        return {
          id: r.repairOrder?.id,
          label: `RO# ${r.repairOrder?.localId!}`,
          description: `🧑 ${formatName(
            r.customer?.information
          )} \n🚗 ${describeVehicleYMM(r.vehicle!.info!)}\n🔧 ${
            r.repairOrder?.info?.concern || ''
          }\n`
        }
      })
      return flattened
    },
    labelKey: 'label',
    descriptionKey: 'description',
    defaultIcon: '🔧',
    searchTabEnabled: true
  })
  window.CommandBar.addRecordAction('orders', {
    text: 'View Repair Order',
    name: 'view-order',
    template: {
      type: 'link',
      value: '/estimates/{{record.id}}',
      operation: 'router'
    }
  })

  window.CommandBar.addRecords('estimates', [], {
    onInputChange: async (query: any) => {
      const results = await sdk.searchRepairOrderByKeyword({
        keyword: query,
        shop: loggingAssert('ShopId not set', userShopId),
        authorization: currentUser.accessToken! as string,
        isArchived: false,
        isEstimate: true
      })
      const flattened = results.map((r) => {
        return {
          id: r.repairOrder?.id,
          label: `E# ${r.repairOrder?.localId!}`,
          description: `🧑 ${formatName(
            r.customer?.information
          )} \n🚗 ${describeVehicleYMM(r.vehicle!.info!)}\n🔧 ${
            r.repairOrder?.info?.concern || ''
          }\n`
        }
      })
      return flattened
    },
    labelKey: 'label',
    descriptionKey: 'description',
    defaultIcon: '🔧',
    searchTabEnabled: true
  })
  window.CommandBar.addRecordAction('estimates', {
    text: 'View Estimate',
    name: 'view-estimate',
    template: {
      type: 'link',
      value: '/estimates/{{record.id}}',
      operation: 'router'
    }
  })
}
