import { Backdrop, CircularProgress } from '@mui/material'

export const LoadingBackdrop = () => {
  return (
    <Backdrop
      open={true}
      sx={{
        bgcolor: 'rgba(255,255,255,0.5)',
        position: 'absolute',
        margin: 'auto',
        zIndex: 1
      }}>
      <CircularProgress />
    </Backdrop>
  )
}
