import {
  Client as ConversationsClient,
  Conversation
} from '@twilio/conversations'
import React, {
  createContext,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'

import { sdk } from '../../config'
import useAccessTokenAndShopId from '../../hooks/useAccessTokenAndShopId/useAccessTokenAndShopId'
import useInitializeListeners from './useInitializeListeners'

export const MERGED_CUSTOMER_CONVERSATION_UNIQUE_NAME =
  'd08261ec-0e3d-4dc4-acd1-7b4be9daf6b1'
export const MERGED_REPAIR_ORDERS_CONVERSATION_UNIQUE_NAME =
  'b93af511-8177-492b-b116-7597854464e4'

type TwilioConversationsContextValues = {
  connectionStatus: string
  subscribedConversations: Conversation[]
  conversationUniqueNamesWithUnreadMessages: string[]
  conversationClient: ConversationsClient | undefined
  fetchMessagesFlag: boolean
}

export const TwilioConversationsContext =
  createContext<TwilioConversationsContextValues>({
    connectionStatus: 'disconnected',
    subscribedConversations: [],
    conversationUniqueNamesWithUnreadMessages: [],
    conversationClient: undefined,
    fetchMessagesFlag: false
  })

interface Props {
  children?: React.ReactNode
}

export const TwilioConversationsProvider: FC<Props> = ({ children }) => {
  const { accessToken, shopId } = useAccessTokenAndShopId()
  const initStarted = useRef<boolean>(false)
  const conversationsClient = useRef<ConversationsClient | undefined>()
  const [connectionStatus, setConnectionStatus] = useState('disconnected')
  const [subscribedConversations, setSubscribedConversations] = useState<
    Conversation[]
  >([])
  const [fetchMessagesFlag, setFetchMessagesFlag] = useState(false)
  const newMessageReceived = () =>
    setFetchMessagesFlag((previousFlag) => !previousFlag)
  const [
    conversationUniqueNamesWithUnreadMessages,
    setConversationUniqueNamesWithUnreadMessages
  ] = useState<string[]>([])
  const { initializeListeners } = useInitializeListeners()

  const initializeConversationsClient = useCallback(async () => {
    const token = await sdk.getTwilioAccessToken({
      authorization: accessToken,
      body: shopId
    })
    conversationsClient.current = new ConversationsClient(token, {
      logLevel: 'error'
    })
    initializeListeners(
      conversationsClient.current,
      setConnectionStatus,
      setSubscribedConversations,
      setConversationUniqueNamesWithUnreadMessages,
      newMessageReceived
    )
  }, [accessToken, shopId, initializeListeners])

  useEffect(() => {
    if (!initStarted.current) {
      initStarted.current = true
      initializeConversationsClient()
    }
  }, [initializeConversationsClient])

  return (
    <TwilioConversationsContext.Provider
      value={{
        subscribedConversations,
        conversationUniqueNamesWithUnreadMessages,
        connectionStatus,
        conversationClient: conversationsClient.current,
        fetchMessagesFlag
      }}>
      {children}
    </TwilioConversationsContext.Provider>
  )
}
