import { useCallback } from 'react'

import { useCreatePushSubscriptionMutation } from '../../redux/api/notificationApi'
import { sendBugReport } from '../../utils/handleError/sendBugReport'
import useAccessTokenAndShopId from '../useAccessTokenAndShopId/useAccessTokenAndShopId'
// Convert a base64 string to Uint8Array.
const urlB64ToUint8Array = (base64String: string) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')
  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}
const VAPID_PUBLIC_KEY =
  'BMCULiTjJLvCp2xd5HukixkjFp6nCNCknZ0SAyQBnsywcEijSIRuoPaTX7tNEAiJvJe6zWFpuCwceoL3WI3nhe0'

const useRegisterNotification: () => {
  registerNotification: () => void
} = () => {
  const { userId, shopId, accessToken } = useAccessTokenAndShopId()
  const [createPushSubscription] = useCreatePushSubscriptionMutation()

  const registerNotification = useCallback(async () => {
    if (!('serviceWorker' in navigator)) {
      sendBugReport(
        'warning',
        'Cannot find service worker',
        `Cannot find service worker. User id ${userId}. Shop id ${shopId}`
      )
      return
    }

    if (!('PushManager' in window)) {
      sendBugReport(
        'warning',
        'Push not supported',
        `This browser does not support push notification. User id ${userId}. Shop id ${shopId}`
      )
      return
    }
    const registration = await navigator.serviceWorker.getRegistration()
    if (!registration) {
      sendBugReport(
        'error',
        'Registration for push notification failed',
        `Registration for push notification failed. User id ${userId}. Shop id ${shopId}`
      )
      return
    }
    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlB64ToUint8Array(VAPID_PUBLIC_KEY)
    })
    if (!subscription) {
      sendBugReport(
        'error',
        'Subscription for push notification failed',
        `Subscription for push notification failed. User id ${userId}. Shop id ${shopId}`
      )
    }
    await createPushSubscription({
      authorization: accessToken,
      pushSubscriptionInfo: {
        userId,
        endpoint: subscription.endpoint,
        key: subscription.toJSON().keys?.p256dh || '',
        auth: subscription.toJSON().keys?.auth || ''
      }
    })
    navigator.serviceWorker.controller?.postMessage(accessToken)
  }, [accessToken, createPushSubscription, shopId, userId])

  return { registerNotification }
}

export default useRegisterNotification
