import 'firebase/compat/auth'

import { datadogRum } from '@datadog/browser-rum'
import { FirebaseApp } from 'firebase/app'
import React, { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  LSCurrentUserAccessToken,
  LSCurrentUserShopId,
  sdk
} from '../../config'
import { getEnv } from '../../config/configs'
import { useFirebaseAuth } from '../../hooks'
import { User } from '../../models'
import { handleSdkError } from '../../utils'
import { CommandBarSetup } from './CommandBarSetup'
import { useLoginWithToken } from './useLoginWithToken'

interface AuthContextValues {
  currentUser: User | undefined
  userShopId?: string
  firebaseReady?: boolean
  errorMessage?: string
  userLoading?: boolean
  appLoading?: boolean
  firebaseApp?: FirebaseApp
  logOut: () => void
  setCurrentUser: React.Dispatch<React.SetStateAction<User | undefined>>
  setUserShopId: React.Dispatch<React.SetStateAction<string | undefined>>
  refreshUser: () => Promise<void>
  initialLoginAttemptFinished: boolean
}

interface Props {
  firebaseApp: FirebaseApp
  children?: React.ReactNode
}

export const AuthContext = React.createContext<AuthContextValues>({
  currentUser: undefined,
  userShopId: undefined,
  logOut: () => undefined,
  setCurrentUser: () => undefined,
  refreshUser: () => new Promise(() => undefined),
  setUserShopId: () => undefined,
  initialLoginAttemptFinished: false
})

export const AuthProvider: React.FC<Props> = ({ children, firebaseApp }) => {
  const [currentUser, setCurrentUser] = useState<User | undefined>()
  const [userShopId, setUserShopId] = useState<string | undefined>()
  const [errorMessage, setErrorMessage] = useState('')
  const [userLoading, setUserLoading] = useState(false)
  const [appLoading, setAppLoading] = useState(false)
  const [initialLoginAttemptFinished, setInitialLoginAttemptFinished] =
    useState(false)
  const loginWithToken = useLoginWithToken(
    setCurrentUser,
    setUserShopId,
    setErrorMessage
  )
  const [searchParams] = useSearchParams()

  const { firebaseReady, errorMessage: firebaseErrorMessage } =
    useFirebaseAuth(firebaseApp)

  const refreshUser = useCallback(
    async (initialLoad?: boolean) => {
      const setLoadState = initialLoad ? setAppLoading : setUserLoading
      const token = localStorage.getItem(LSCurrentUserAccessToken)
      if (!token) {
        const overrideToken = searchParams.get('override_token')
        if (overrideToken && initialLoad) {
          await loginWithToken(overrideToken, setLoadState)
        }
        return
      }
      await loginWithToken(token, setLoadState)
    },
    [loginWithToken, searchParams]
  )

  useEffect(() => {
    async function initialUserRefresh() {
      try {
        await refreshUser(true)
      } finally {
        setInitialLoginAttemptFinished(true)
      }
    }

    initialUserRefresh()
  }, [refreshUser])

  const logOut = async () => {
    setAppLoading(true)

    try {
      await sdk.logout({
        authorization: currentUser?.accessToken as string
      })
      setCurrentUser(undefined)
      localStorage.removeItem(LSCurrentUserAccessToken)
      setUserShopId(undefined)
      localStorage.removeItem(LSCurrentUserShopId)
    } catch (error) {
      const message = (await handleSdkError(error)).message
      setErrorMessage(message)
    } finally {
      setAppLoading(false)
    }
  }

  useEffect(() => {
    if (!currentUser) {
      window.Upscope('reset')
      window._hsq = (window._hsq || []).filter((i: any) => i[0] !== 'identify')
      if (getEnv() !== 'development') {
        datadogRum.clearUser()
      }
    } else {
      window.Upscope('updateConnection', {
        uniqueId: currentUser.id!,
        identities: [currentUser.name!, currentUser.email!]
      })
      window._hsq = window._hsq || []
      window._hsq.push([
        'identify',
        {
          email: currentUser.email,
          id: currentUser.id
        }
      ])
      if (getEnv() !== 'development') {
        datadogRum.setUser({
          id: currentUser.id!,
          name: currentUser.name!,
          email: currentUser.email!,
          shopId: currentUser.shop?.id || '',
          shopName: currentUser.shop?.info?.name || ''
        })
      }
    }
  }, [currentUser])

  useEffect(() => {
    if (currentUser && userShopId) {
      CommandBarSetup(currentUser!, userShopId)
    }
  }, [userShopId, currentUser])

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        userShopId,
        firebaseApp,
        errorMessage: errorMessage || firebaseErrorMessage,
        firebaseReady,
        appLoading,
        userLoading,
        logOut,
        setCurrentUser,
        setUserShopId,
        refreshUser,
        initialLoginAttemptFinished
      }}>
      {children}
    </AuthContext.Provider>
  )
}
