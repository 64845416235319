import { useMemo } from 'react'

import {
  useGetEmployeeRolesQuery,
  useListRepairOrdersWithRelatedQuery
} from '../../redux/api'
import { useGetProductivityStatusQuery } from '../../redux/api/shopMatrixApi'
import {
  EmployeeRole,
  ProductivityStatusEntry,
  RepairOrderWithRelated,
  TimeClockEntry
} from '../../sdk'
import useAccessTokenAndShopId from '../useAccessTokenAndShopId/useAccessTokenAndShopId'
export interface TimeClockWithRelated extends TimeClockEntry {
  relatedRepairOrder: RepairOrderWithRelated | undefined
  relatedStatus: ProductivityStatusEntry | undefined
  relatedEmployee: EmployeeRole | undefined
}
export function useGetTimeClockWithRelated(
  timeClocks: TimeClockEntry[] | undefined
): TimeClockWithRelated[] | undefined {
  const { accessToken, shopId } = useAccessTokenAndShopId()
  const { data: employees } = useGetEmployeeRolesQuery({
    authorization: accessToken,
    shop: shopId
  })
  const { data: productivityStatuses } = useGetProductivityStatusQuery({
    authorization: accessToken,
    shop: shopId
  })

  const roIds = useMemo(
    () =>
      (timeClocks || [])
        .filter((t) => t.info!.repairOrder)
        .map((t) => t.info!.repairOrder) as string[],
    [timeClocks]
  )
  const { data: repairOrders } = useListRepairOrdersWithRelatedQuery(
    {
      authorization: accessToken,
      shop: shopId,
      repairOrder: roIds
    },
    {
      skip: roIds.length === 0
    }
  )
  const timeClockWithRelated = useMemo(
    () =>
      timeClocks?.map((timeClock) => {
        const relatedRepairOrder = repairOrders?.find(
          (repairOrder) =>
            repairOrder.repairOrder!.id === timeClock.info!.repairOrder
        )
        const relatedStatus = productivityStatuses?.find(
          (p) => p.id === timeClock.info!.status
        )
        const relatedEmployee = employees?.find(
          (e) => e.userProfile!.id === timeClock.info!.employee
        )
        return {
          ...timeClock,
          relatedRepairOrder,
          relatedStatus,
          relatedEmployee
        }
      }),
    [employees, productivityStatuses, repairOrders, timeClocks]
  )
  return timeClockWithRelated
}
