import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'

import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation'
import { TABLET_WIDTH_BREAKPOINT } from '../../config/breakPoints'
import useAccessTokenAndShopId from '../../hooks/useAccessTokenAndShopId/useAccessTokenAndShopId'
import useFilteredRoStatuses from '../../hooks/useFilteredRoStatuses/useFilteredRoStatuses'
import {
  TimeClockWithRelated,
  useGetTimeClockWithRelated
} from '../../hooks/useGetTimeClockWithRelated/useGetTimeClockWithRelated'
import useRegisterNotification from '../../hooks/useRegisterNotification/useRegisterNotification'
import useRequestNotificationPermission from '../../hooks/useRequestNotificationPermission/useRequestNotificationPermission'
import { FeatureFlags } from '../../models/FeatureFlags'
import {
  useGetEmployeeRolesQuery,
  useGetShopProfileQuery,
  useGetUsersWithPermissionQuery
} from '../../redux/api'
import {
  useCheckFeaturesForShopQuery,
  useGetAccountQuery
} from '../../redux/api/independentApi'
import { useGetProductivityStatusQuery } from '../../redux/api/shopMatrixApi'
import { useListTimeClockEntriesQuery } from '../../redux/api/timeClockApi'
import {
  CapitalOsAccountStatusEnum,
  EmployeeRole,
  GetUsersWithPermissionActEnum,
  PartsTechPartTaxonomy,
  ProductivityStatusEntry,
  RepairOrderStatusEntry,
  ShopEntry,
  UserProfile
} from '../../sdk'
import { WindowSizeContext } from '../WindowSizeProvider/WindowSizeProvider'
import LoadLocalPartTaxonomy from './loadLocalPartTaxonomy?worker'

interface AppContextValues {
  overTabletBreakpoint: boolean
  cachedPartTaxonomy: PartsTechPartTaxonomy | undefined
  shopProfile: ShopEntry | undefined
  technicians: UserProfile[] | undefined
  repairOrderStatuses: RepairOrderStatusEntry[] | undefined
  gridBoxHeight: number
  employees: EmployeeRole[] | undefined
  openTimeClockWithRelated: TimeClockWithRelated | undefined
  latestClosedTimeClockWithRelated: TimeClockWithRelated | undefined
  featureFlags: { [key: string]: boolean } | undefined
  productivityStatuses: ProductivityStatusEntry[] | undefined
  canAccessExpenseCard: boolean | undefined
}

export const AppContext = createContext<AppContextValues>({
  overTabletBreakpoint: true,
  cachedPartTaxonomy: undefined,
  shopProfile: undefined,
  technicians: undefined,
  repairOrderStatuses: undefined,
  employees: undefined,
  gridBoxHeight: 0,
  openTimeClockWithRelated: undefined,
  latestClosedTimeClockWithRelated: undefined,
  featureFlags: undefined,
  productivityStatuses: undefined,
  canAccessExpenseCard: undefined
})

interface Props {
  children?: React.ReactNode
}

export const AppProvider: FC<Props> = ({ children }) => {
  const initStarted = useRef<boolean>(false)
  const workerRef = useRef<Worker>()
  const { userId, shopId, accessToken } = useAccessTokenAndShopId()
  const { permission, askPermission } = useRequestNotificationPermission()
  const { registerNotification } = useRegisterNotification()
  const { width, height } = useContext(WindowSizeContext)
  const overTabletBreakpoint = width >= TABLET_WIDTH_BREAKPOINT
  const gridBoxHeight = height < 500 ? 300 : height - 200
  const [loadingTaxonomy, setLoadingTaxonomy] = useState(false)
  const [cachedPartTaxonomy, setCachedPartTaxonomy] =
    useState<PartsTechPartTaxonomy>()

  const { data: featureFlags, isLoading: loadingFeatureFlags } =
    useCheckFeaturesForShopQuery(
      {
        authorization: accessToken,
        shop: shopId,
        features: Object.values(FeatureFlags)
      },
      { refetchOnMountOrArgChange: true }
    )

  const { data: technicians, isLoading: loadingTechs } =
    useGetUsersWithPermissionQuery({
      authorization: accessToken,
      shop: shopId,
      act: GetUsersWithPermissionActEnum.IsTechnician
    })

  useEffect(() => {
    if (!initStarted.current && !workerRef.current) {
      workerRef.current = new LoadLocalPartTaxonomy()
      initStarted.current = true
      setLoadingTaxonomy(true)
      workerRef.current.onmessage = function (event) {
        setCachedPartTaxonomy(event.data)
        setLoadingTaxonomy(false)
      }
    }
    return () => workerRef.current?.terminate()
  }, [])

  const { data: shopProfile, isLoading: loadingShop } = useGetShopProfileQuery(
    {
      authorization: accessToken,
      id: shopId
    },
    { refetchOnMountOrArgChange: true }
  )
  const { data: repairOrderStatuses, isLoading: loadingStatuses } =
    useFilteredRoStatuses()
  const { data: employees, isLoading: loadingEmployees } =
    useGetEmployeeRolesQuery(
      {
        authorization: accessToken,
        shop: shopId
      },
      { refetchOnMountOrArgChange: true }
    )
  const { data: productivityStatuses, isLoading: loadingProductivity } =
    useGetProductivityStatusQuery(
      {
        authorization: accessToken,
        shop: shopId
      },
      { refetchOnMountOrArgChange: true }
    )
  const { data: openTimeClocks, isLoading: isLoadingOpen } =
    useListTimeClockEntriesQuery(
      {
        authorization: accessToken,
        shop: shopId,
        employee: [userId],
        isOpenOnly: true
      },
      { refetchOnMountOrArgChange: true }
    )
  const openTimeClocksWithRelated = useGetTimeClockWithRelated(openTimeClocks)
  const openTimeClockWithRelated = useMemo(
    () => openTimeClocksWithRelated && openTimeClocksWithRelated[0],
    [openTimeClocksWithRelated]
  )
  const { data: latestClosedTimeClocks, isLoading: loadingLatest } =
    useListTimeClockEntriesQuery(
      {
        authorization: accessToken,
        shop: shopId,
        employee: [userId],
        isClosedOnly: true,
        numResults: 1
      },
      { refetchOnMountOrArgChange: true }
    )
  const latestClosedTimeClocksWithRelated = useGetTimeClockWithRelated(
    latestClosedTimeClocks
  )
  const latestClosedTimeClockWithRelated = useMemo(
    () =>
      latestClosedTimeClocksWithRelated && latestClosedTimeClocksWithRelated[0],
    [latestClosedTimeClocksWithRelated]
  )
  useEffect(() => {
    if (permission !== 'denied') {
      askPermission()
    }
  }, [askPermission, featureFlags, permission])
  useEffect(() => {
    if (permission === 'granted') {
      registerNotification()
    }
  }, [featureFlags, permission, registerNotification])

  // ---------- CapitalOS permissions ----------

  const { data: expenseCardUsers, isLoading: isLoadingExpenseCardUsers } =
    useGetUsersWithPermissionQuery({
      authorization: accessToken,
      shop: shopId,
      act: GetUsersWithPermissionActEnum.ManageExpenseCards
    })

  const hasManageExpenseCardsPermissions = useMemo(() => {
    return (
      expenseCardUsers &&
      expenseCardUsers.some((userProfile) => userProfile.id === userId)
    )
  }, [expenseCardUsers, userId])

  const { data: capitalOsAccount, isLoading: isLoadingCapitalOsAccount } =
    useGetAccountQuery(
      {
        authorization: accessToken,
        shop: shopId
      },
      {
        skip:
          hasManageExpenseCardsPermissions === false ||
          hasManageExpenseCardsPermissions === undefined
      }
    )

  const hasEligibleAccount = useMemo(() => {
    return (
      capitalOsAccount &&
      capitalOsAccount.status !== CapitalOsAccountStatusEnum.Ineligible
    )
  }, [capitalOsAccount])

  const hasFeatureFlag = useMemo(() => {
    return featureFlags && featureFlags[FeatureFlags.ExpenseCard]
  }, [featureFlags])

  const canAccessExpenseCard = useMemo(() => {
    return (
      hasManageExpenseCardsPermissions && hasEligibleAccount && hasFeatureFlag
    )
  }, [hasEligibleAccount, hasFeatureFlag, hasManageExpenseCardsPermissions])

  const isLoading = useMemo(
    () =>
      loadingEmployees ||
      loadingShop ||
      loadingStatuses ||
      loadingStatuses ||
      loadingTaxonomy ||
      loadingTechs ||
      isLoadingOpen ||
      loadingFeatureFlags ||
      loadingProductivity ||
      loadingLatest ||
      isLoadingExpenseCardUsers ||
      isLoadingCapitalOsAccount,
    [
      isLoadingCapitalOsAccount,
      isLoadingExpenseCardUsers,
      isLoadingOpen,
      loadingEmployees,
      loadingFeatureFlags,
      loadingLatest,
      loadingProductivity,
      loadingShop,
      loadingStatuses,
      loadingTaxonomy,
      loadingTechs
    ]
  )

  return isLoading ? (
    <LoadingAnimation />
  ) : (
    <AppContext.Provider
      value={{
        overTabletBreakpoint,
        cachedPartTaxonomy,
        shopProfile,
        technicians,
        repairOrderStatuses,
        employees,
        gridBoxHeight,
        openTimeClockWithRelated,
        latestClosedTimeClockWithRelated,
        featureFlags,
        productivityStatuses,
        canAccessExpenseCard
      }}>
      {children}
    </AppContext.Provider>
  )
}
