// - Create new feature flag and strategy in gitlab https://gitlab.com/intune-auto/intune-frontend/-/feature_flags
// Add feature flag name to FeatureFlagEnum
export enum FeatureFlags {
  DigitalInspection = 'digital_inspection',
  TextToPay = 'text_to_pay',
  TimeClock = 'time_clock',
  CounterSales = 'counter_sales',
  CustomerFeedbackMessaging = 'customer_feedback_messaging',
  ShopDiscount = 'shop_discount',
  TruSpeed = 'truspeed',
  Textract = 'textract',
  ExpenseCard = 'expense_card',
  CarfaxDemo = 'carfax_demo',
  AppointmentSchedule = 'appointment_schedule',
  ReplicacheDvi = 'replicache_dvi',
  ReadOnly = 'read_only'
}
