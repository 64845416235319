import { Conversation } from '@twilio/conversations'
import { useCallback } from 'react'

const useGetMostRecentConversations = () => {
  const addOrUpdateConversation = useCallback(
    async (
      conversationToAddOrUpdate: Conversation,
      setSubscribedConversations: React.Dispatch<
        React.SetStateAction<Conversation[]>
      >,
      setConversationUniqueNamesWithUnreadMessages: React.Dispatch<
        React.SetStateAction<string[]>
      >
    ) => {
      setSubscribedConversations((previousConversations) => {
        const newSubscribedConversations = previousConversations.filter(
          (c) => c.sid !== conversationToAddOrUpdate.sid
        )
        return [...newSubscribedConversations, conversationToAddOrUpdate]
      })
      if (conversationToAddOrUpdate.uniqueName === null) {
        return
      }
      const numUnreadMessages =
        await conversationToAddOrUpdate.getUnreadMessagesCount()
      if (
        numUnreadMessages !== 0 &&
        conversationToAddOrUpdate.lastMessage !== undefined
      ) {
        setConversationUniqueNamesWithUnreadMessages((previousUniqueNames) => {
          if (conversationToAddOrUpdate.uniqueName === null) {
            return previousUniqueNames
          }
          const newUniqueNames = Array.from(
            new Set([
              ...previousUniqueNames,
              conversationToAddOrUpdate.uniqueName
            ])
          )
          return newUniqueNames
        })
      } else {
        setConversationUniqueNamesWithUnreadMessages((previousUniqueNames) => {
          if (conversationToAddOrUpdate.uniqueName === null) {
            return previousUniqueNames
          }
          const newUniqueNames = previousUniqueNames.filter(
            (uniqueName) => uniqueName !== conversationToAddOrUpdate.uniqueName
          )
          return newUniqueNames
        })
      }
    },
    []
  )

  const removeConversation = useCallback(
    async (
      conversationToRemove: Conversation,
      setSubscribedConversations: React.Dispatch<
        React.SetStateAction<Conversation[]>
      >,
      setConversationUniqueNamesWithUnreadMessages: React.Dispatch<
        React.SetStateAction<string[]>
      >
    ) => {
      setSubscribedConversations((previousConversations) => {
        const newSubscribedConversations = previousConversations.filter(
          (c) => c.sid !== conversationToRemove.sid
        )
        return newSubscribedConversations
      })
      setConversationUniqueNamesWithUnreadMessages((previousUniqueNames) => {
        if (conversationToRemove.uniqueName === null) {
          return previousUniqueNames
        }
        const newUniqueNames = previousUniqueNames.filter(
          (u) => u !== conversationToRemove.uniqueName
        )
        return newUniqueNames
      })
    },
    []
  )

  return {
    addOrUpdateConversation,
    removeConversation
  }
}

export default useGetMostRecentConversations
