import { sdk } from '../../config'
import {
  CannedJobCustomPartEntry,
  CannedJobEntry,
  CannedJobExpanded,
  CannedJobMotorFluidsEntry,
  CannedJobMotorLaborEntry,
  CannedJobRelatedEntry,
  CopyCannedJobRequest,
  CreateCannedJobCustomFeeRequest,
  CreateCannedJobCustomLaborRequest,
  CreateCannedJobCustomPartRequest,
  CreateCannedJobMotorFluidsRequest,
  CreateCannedJobMotorLaborRequest,
  CreateCannedJobPartLookupRequest,
  CreateCannedJobRelatedRequest,
  CreateCannedJobRequest,
  CreateCannedJobToInventoryPartRequest,
  CreateCannedJobToInventoryProductRequest,
  CreateServiceFromCannedJobRequest,
  DeleteCannedJobCustomFeeRequest,
  DeleteCannedJobCustomLaborRequest,
  DeleteCannedJobCustomPartRequest,
  DeleteCannedJobMotorFluidsRequest,
  DeleteCannedJobMotorLaborRequest,
  DeleteCannedJobPartLookupRequest,
  DeleteCannedJobRelatedRequest,
  DeleteCannedJobToInventoryPartRequest,
  DeleteCannedJobToInventoryProductRequest,
  GetCannedJobExpandedByApiKeyRequest,
  GetCannedJobExpandedRequest,
  GetCannedJobMotorFluidsRequest,
  GetCannedJobMotorLaborRequest,
  GetCannedJobRelatedRequest,
  GetCannedJobRelatedToParentRequest,
  GetCannedJobsRequest,
  ListCannedJobsRequest,
  ListCannedPartsRequest,
  UpdateCannedJobCustomFeeRequest,
  UpdateCannedJobCustomLaborRequest,
  UpdateCannedJobCustomPartRequest,
  UpdateCannedJobMotorFluidsRequest,
  UpdateCannedJobMotorLaborRequest,
  UpdateCannedJobPartLookupRequest,
  UpdateCannedJobRelatedRequest,
  UpdateCannedJobRequest,
  UpdateCannedJobToInventoryPartRequest,
  UpdateCannedJobToInventoryProductRequest
} from '../../sdk'
import { stripQuotes } from '../../utils'
import { api } from '.'
const listCannedJobs = sdk.listCannedJobs.bind(sdk)
const updateCannedJob = sdk.updateCannedJob.bind(sdk)
const createCannedJob = sdk.createCannedJob.bind(sdk)
const copyCannedJob = sdk.copyCannedJob.bind(sdk)
const getCannedJobs = sdk.getCannedJobs.bind(sdk)
const getCannedJobExpanded = sdk.getCannedJobExpanded.bind(sdk)
const createCannedJobCustomLabor = sdk.createCannedJobCustomLabor.bind(sdk)
const updateCannedJobCustomLabor = sdk.updateCannedJobCustomLabor.bind(sdk)
const deleteCannedJobCustomLabor = sdk.deleteCannedJobCustomLabor.bind(sdk)
const createCannedJobCustomPart = sdk.createCannedJobCustomPart.bind(sdk)
const updateCannedJobCustomPart = sdk.updateCannedJobCustomPart.bind(sdk)
const deleteCannedJobCustomPart = sdk.deleteCannedJobCustomPart.bind(sdk)
const createCannedJobMotorLabor = sdk.createCannedJobMotorLabor.bind(sdk)
const updateCannedJobMotorLabor = sdk.updateCannedJobMotorLabor.bind(sdk)
const deleteCannedJobMotorLabor = sdk.deleteCannedJobMotorLabor.bind(sdk)
const createCannedJobPartLookup = sdk.createCannedJobPartLookup.bind(sdk)
const updateCannedJobPartLookup = sdk.updateCannedJobPartLookup.bind(sdk)
const deleteCannedJobPartLookup = sdk.deleteCannedJobPartLookup.bind(sdk)
const createCannedJobToInventoryPart =
  sdk.createCannedJobToInventoryPart.bind(sdk)
const updateCannedJobToInventoryPart =
  sdk.updateCannedJobToInventoryPart.bind(sdk)
const deleteCannedJobToInventoryPart =
  sdk.deleteCannedJobToInventoryPart.bind(sdk)
const getCannedJobMotorFluids = sdk.getCannedJobMotorFluids.bind(sdk)
const getCannedJobMotorLabor = sdk.getCannedJobMotorLabor.bind(sdk)
const createCannedJobMotorFluids = sdk.createCannedJobMotorFluids.bind(sdk)
const updateCannedJobMotorFluids = sdk.updateCannedJobMotorFluids.bind(sdk)
const deleteCannedJobMotorFluids = sdk.deleteCannedJobMotorFluids.bind(sdk)
const createCannedJobCustomFee = sdk.createCannedJobCustomFee.bind(sdk)
const updateCannedJobCustomFee = sdk.updateCannedJobCustomFee.bind(sdk)
const deleteCannedJobCustomFee = sdk.deleteCannedJobCustomFee.bind(sdk)
const createCannedJobToInventoryProduct =
  sdk.createCannedJobToInventoryProduct.bind(sdk)
const updateCannedJobToInventoryProduct =
  sdk.updateCannedJobToInventoryProduct.bind(sdk)
const deleteCannedJobToInventoryProduct =
  sdk.deleteCannedJobToInventoryProduct.bind(sdk)
const listCannedParts = sdk.listCannedParts.bind(sdk)
const getCannedJobExpandedByApiKey = sdk.getCannedJobExpandedByApiKey.bind(sdk)
const createCannedJobRelated = sdk.createCannedJobRelated.bind(sdk)
const getCannedJobRelated = sdk.getCannedJobRelated.bind(sdk)
const getCannedJobRelatedToParent = sdk.getCannedJobRelatedToParent.bind(sdk)
const updateCannedJobRelated = sdk.updateCannedJobRelated.bind(sdk)
const deleteCannedJobRelated = sdk.deleteCannedJobRelated.bind(sdk)
const createServiceFromCannedJob = sdk.createServiceFromCannedJob.bind(sdk)

// This api slice is for canned job related APIs
const cannedJobApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listCannedJobs: builder.query<CannedJobEntry[], ListCannedJobsRequest>({
      query: (req) => () => listCannedJobs(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'CannedJob' as const,
                id: entry.id!
              })),
              'CannedJob'
            ]
          : ['CannedJob']
    }),
    updateCannedJob: builder.mutation<void, UpdateCannedJobRequest>({
      query: (req) => () => updateCannedJob(req),
      invalidatesTags: (result, error, req) => [
        { type: 'CannedJob', id: req.cannedJobEntry!.id! },
        { type: 'CannedJobExpanded', id: req.cannedJobEntry!.id! }
      ]
    }),
    createCannedJob: builder.mutation<string, CreateCannedJobRequest>({
      query: (req) => () => createCannedJob(req),
      transformResponse: (response: string) => stripQuotes(response),
      invalidatesTags: [{ type: 'CannedJob' }, { type: 'CannedJobExpanded' }]
    }),
    copyCannedJob: builder.mutation<string, CopyCannedJobRequest>({
      query: (req) => () => copyCannedJob(req),
      invalidatesTags: [{ type: 'CannedJob' }, { type: 'CannedJobExpanded' }]
    }),
    getCannedJobs: builder.query<CannedJobEntry[], GetCannedJobsRequest>({
      query: (req) => () => getCannedJobs(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'CannedJob' as const,
                id: entry!.id!
              })),
              'CannedJob'
            ]
          : ['CannedJob']
    }),
    getCannedJobExpanded: builder.query<
      CannedJobExpanded[],
      GetCannedJobExpandedRequest
    >({
      query: (req) => () => getCannedJobExpanded(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'CannedJobExpanded' as const,
                id: entry.entry!.id!
              })),
              'CannedJobExpanded'
            ]
          : ['CannedJobExpanded']
    }),
    getCannedJobExpandedByApiKey: builder.query<
      CannedJobExpanded[],
      GetCannedJobExpandedByApiKeyRequest
    >({
      query: (req) => () => getCannedJobExpandedByApiKey(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'CannedJobExpanded' as const,
                id: entry.entry!.id!
              })),
              'CannedJobExpanded'
            ]
          : ['CannedJobExpanded' as const]
    }),
    createCannedJobCustomLabor: builder.mutation<
      string,
      CreateCannedJobCustomLaborRequest
    >({
      query: (req) => () => createCannedJobCustomLabor(req),
      transformResponse: (response: string) => stripQuotes(response),
      invalidatesTags: [{ type: 'CannedJobExpanded' }]
    }),
    updateCannedJobCustomLabor: builder.mutation<
      void,
      UpdateCannedJobCustomLaborRequest
    >({
      query: (req) => () => updateCannedJobCustomLabor(req),
      invalidatesTags: (result, error, req) => [
        {
          type: 'CannedJobExpanded',
          id: req.cannedJobCustomLaborEntry!.info!.cannedJob!
        }
      ]
    }),
    deleteCannedJobCustomLabor: builder.mutation<
      void,
      DeleteCannedJobCustomLaborRequest
    >({
      query: (req) => () => deleteCannedJobCustomLabor(req),
      invalidatesTags: (result, error, req) => [
        {
          type: 'CannedJobExpanded',
          id: req.cannedJob
        }
      ]
    }),
    createCannedJobMotorLabor: builder.mutation<
      string,
      CreateCannedJobMotorLaborRequest
    >({
      query: (req) => () => createCannedJobMotorLabor(req),
      transformResponse: (response: string) => stripQuotes(response),
      invalidatesTags: [{ type: 'CannedJobExpanded' }]
    }),
    updateCannedJobMotorLabor: builder.mutation<
      void,
      UpdateCannedJobMotorLaborRequest
    >({
      query: (req) => () => updateCannedJobMotorLabor(req),
      invalidatesTags: (result, error, req) => [
        {
          type: 'CannedJobExpanded',
          id: req.cannedJobMotorLaborEntry!.info!.cannedJob!
        }
      ]
    }),
    deleteCannedJobMotorLabor: builder.mutation<
      void,
      DeleteCannedJobMotorLaborRequest
    >({
      query: (req) => () => deleteCannedJobMotorLabor(req),
      invalidatesTags: (result, error, req) => [
        {
          type: 'CannedJobExpanded',
          id: req.cannedJob
        }
      ]
    }),
    createCannedJobCustomPart: builder.mutation<
      string,
      CreateCannedJobCustomPartRequest
    >({
      query: (req) => () => createCannedJobCustomPart(req),
      transformResponse: (response: string) => stripQuotes(response),
      invalidatesTags: ['CannedJobExpanded', 'CannedPart', 'CounterSales']
    }),
    updateCannedJobCustomPart: builder.mutation<
      void,
      UpdateCannedJobCustomPartRequest
    >({
      query: (req) => () => updateCannedJobCustomPart(req),
      invalidatesTags: (result, error, req) => {
        return req.cannedJobCustomPartEntry!.info!.cannedJob
          ? [
              {
                type: 'CannedJobExpanded',
                id: req.cannedJobCustomPartEntry!.info!.cannedJob!
              },
              'CounterSales'
            ]
          : [
              {
                type: 'CannedPart',
                id: req.cannedJobCustomPartEntry!.id!
              },
              'CounterSales'
            ]
      }
    }),
    deleteCannedJobCustomPart: builder.mutation<
      void,
      DeleteCannedJobCustomPartRequest
    >({
      query: (req) => () => deleteCannedJobCustomPart(req),
      invalidatesTags: (result, error, req) => {
        return req.cannedJob
          ? [
              {
                type: 'CannedJobExpanded',
                id: req.cannedJob
              },
              'CounterSales'
            ]
          : [
              {
                type: 'CannedPart',
                id: req.customPart
              },
              'CounterSales'
            ]
      }
    }),
    createCannedJobPartLookup: builder.mutation<
      string,
      CreateCannedJobPartLookupRequest
    >({
      query: (req) => () => createCannedJobPartLookup(req),
      transformResponse: (response: string) => stripQuotes(response),
      invalidatesTags: [{ type: 'CannedJobExpanded' }]
    }),
    updateCannedJobPartLookup: builder.mutation<
      void,
      UpdateCannedJobPartLookupRequest
    >({
      query: (req) => () => updateCannedJobPartLookup(req),
      invalidatesTags: (result, error, req) => [
        {
          type: 'CannedJobExpanded',
          id: req.cannedJobPartLookupEntry!.info!.cannedJob!
        }
      ]
    }),
    deleteCannedJobPartLookup: builder.mutation<
      void,
      DeleteCannedJobPartLookupRequest
    >({
      query: (req) => () => deleteCannedJobPartLookup(req),
      invalidatesTags: (result, error, req) => [
        {
          type: 'CannedJobExpanded',
          id: req.cannedJob
        }
      ]
    }),
    createCannedJobToInventoryPart: builder.mutation<
      string,
      CreateCannedJobToInventoryPartRequest
    >({
      query: (req) => () => createCannedJobToInventoryPart(req),
      transformResponse: (response: string) => stripQuotes(response),
      invalidatesTags: [{ type: 'CannedJobExpanded' }]
    }),
    updateCannedJobToInventoryPart: builder.mutation<
      void,
      UpdateCannedJobToInventoryPartRequest
    >({
      query: (req) => () => updateCannedJobToInventoryPart(req),
      invalidatesTags: (result, error, req) => [
        {
          type: 'CannedJobExpanded',
          id: req.cannedJobInventoryPartEntry!.info!.cannedJob!
        }
      ]
    }),
    deleteCannedJobToInventoryPart: builder.mutation<
      void,
      DeleteCannedJobToInventoryPartRequest
    >({
      query: (req) => () => deleteCannedJobToInventoryPart(req),
      invalidatesTags: (result, error, req) => [
        {
          type: 'CannedJobExpanded',
          id: req.cannedJob
        }
      ]
    }),
    createCannedJobToInventoryProduct: builder.mutation<
      string,
      CreateCannedJobToInventoryProductRequest
    >({
      query: (req) => () => createCannedJobToInventoryProduct(req),
      transformResponse: (response: string) => stripQuotes(response),
      invalidatesTags: [{ type: 'CannedJobExpanded' }]
    }),
    updateCannedJobToInventoryProduct: builder.mutation<
      void,
      UpdateCannedJobToInventoryProductRequest
    >({
      query: (req) => () => updateCannedJobToInventoryProduct(req),
      invalidatesTags: (result, error, req) => [
        {
          type: 'CannedJobExpanded',
          id: req.cannedJobToInventoryProductEntry!.info!.cannedJob!
        }
      ]
    }),
    deleteCannedJobToInventoryProduct: builder.mutation<
      void,
      DeleteCannedJobToInventoryProductRequest
    >({
      query: (req) => () => deleteCannedJobToInventoryProduct(req),
      invalidatesTags: (result, error, req) => [
        {
          type: 'CannedJobExpanded',
          id: req.cannedJob
        }
      ]
    }),
    getCannedJobMotorFluids: builder.query<
      CannedJobMotorFluidsEntry,
      GetCannedJobMotorFluidsRequest
    >({
      query: (req) => () => getCannedJobMotorFluids(req),
      providesTags: (result) =>
        result
          ? [
              {
                type: 'CannedJobExpanded' as const,
                id: result.info!.cannedJob
              },
              'CannedJobExpanded'
            ]
          : ['CannedJobExpanded']
    }),
    getCannedJobMotorLabor: builder.query<
      CannedJobMotorLaborEntry,
      GetCannedJobMotorLaborRequest
    >({
      query: (req) => () => getCannedJobMotorLabor(req),
      providesTags: (result) =>
        result
          ? [
              {
                type: 'CannedJobExpanded' as const,
                id: result.info!.cannedJob
              },
              'CannedJobExpanded'
            ]
          : ['CannedJobExpanded']
    }),
    createCannedJobMotorFluids: builder.mutation<
      string,
      CreateCannedJobMotorFluidsRequest
    >({
      query: (req) => () => createCannedJobMotorFluids(req),
      transformResponse: (response: string) => stripQuotes(response),
      invalidatesTags: [{ type: 'CannedJobExpanded' }]
    }),
    updateCannedJobMotorFluids: builder.mutation<
      void,
      UpdateCannedJobMotorFluidsRequest
    >({
      query: (req) => () => updateCannedJobMotorFluids(req),
      invalidatesTags: (result, error, req) => [
        {
          type: 'CannedJobExpanded',
          id: req.cannedJobMotorFluidsEntry!.info!.cannedJob!
        }
      ]
    }),
    deleteCannedJobMotorFluids: builder.mutation<
      void,
      DeleteCannedJobMotorFluidsRequest
    >({
      query: (req) => () => deleteCannedJobMotorFluids(req),
      invalidatesTags: (result, error, req) => [
        {
          type: 'CannedJobExpanded',
          id: req.cannedJob
        }
      ]
    }),
    createCannedJobCustomFee: builder.mutation<
      void,
      CreateCannedJobCustomFeeRequest
    >({
      query: (req) => () => createCannedJobCustomFee(req),
      invalidatesTags: (result, error, req) => [
        {
          type: 'CannedJobExpanded',
          id: req.cannedJobCustomFeeInfo!.cannedJob!
        }
      ]
    }),
    updateCannedJobCustomFee: builder.mutation<
      void,
      UpdateCannedJobCustomFeeRequest
    >({
      query: (req) => () => updateCannedJobCustomFee(req),
      invalidatesTags: (result, error, req) => [
        {
          type: 'CannedJobExpanded',
          id: req.cannedJobCustomFeeEntry!.info!.cannedJob!
        }
      ]
    }),
    deleteCannedJobCustomFee: builder.mutation<
      void,
      DeleteCannedJobCustomFeeRequest
    >({
      query: (req) => () => deleteCannedJobCustomFee(req),
      invalidatesTags: (result, error, req) => [
        {
          type: 'CannedJobExpanded',
          id: req.cannedJob
        }
      ]
    }),
    listCannedParts: builder.query<
      CannedJobCustomPartEntry[],
      ListCannedPartsRequest
    >({
      query: (req) => () => listCannedParts(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'CannedPart' as const,
                id: entry.id!
              })),
              'CannedPart'
            ]
          : ['CannedPart']
    }),
    createCannedJobRelated: builder.mutation<
      string,
      CreateCannedJobRelatedRequest
    >({
      query: (req) => () => createCannedJobRelated(req),
      transformResponse: (response: string) => stripQuotes(response),
      invalidatesTags: (result, error, req) => [
        {
          type: 'CannedJobExpanded',
          id: req.cannedJobRelatedInfo?.parent
        }
      ]
    }),
    updateCannedJobRelated: builder.mutation<
      void,
      UpdateCannedJobRelatedRequest
    >({
      query: (req) => () => updateCannedJobRelated(req),
      invalidatesTags: (result, error, req) => [
        {
          type: 'CannedJobExpanded',
          id: req.cannedJobRelatedEntry?.info?.parent
        }
      ]
    }),
    deleteCannedJobRelated: builder.mutation<
      void,
      DeleteCannedJobRelatedRequest
    >({
      query: (req) => () => deleteCannedJobRelated(req),
      invalidatesTags: [{ type: 'CannedJobExpanded' }]
    }),
    getCannedJobRelated: builder.query<
      CannedJobRelatedEntry[],
      GetCannedJobRelatedRequest
    >({
      query: (req) => () => getCannedJobRelated(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'CannedJobRelated' as const,
                id: entry.id!
              })),
              'CannedJobRelated'
            ]
          : ['CannedJobRelated']
    }),
    getCannedJobRelatedToParent: builder.query<
      CannedJobRelatedEntry[],
      GetCannedJobRelatedToParentRequest
    >({
      query: (req) => () => getCannedJobRelatedToParent(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'CannedJobRelated' as const,
                id: entry.id!
              })),
              'CannedJobRelated'
            ]
          : ['CannedJobRelated']
    }),
    createServiceFromCannedJob: builder.mutation<
      string,
      CreateServiceFromCannedJobRequest
    >({
      query: (req) => () => createServiceFromCannedJob(req),
      transformResponse: (response: string) => stripQuotes(response),
      invalidatesTags: ['Repair Order', 'Service']
    })
  }),

  overrideExisting: true
})

export const {
  useListCannedJobsQuery,
  useUpdateCannedJobMutation,
  useCreateCannedJobMutation,
  useGetCannedJobsQuery,
  useGetCannedJobExpandedQuery,
  useLazyGetCannedJobExpandedQuery,
  useCreateCannedJobCustomLaborMutation,
  useUpdateCannedJobCustomLaborMutation,
  useDeleteCannedJobCustomLaborMutation,
  useCreateCannedJobMotorLaborMutation,
  useUpdateCannedJobMotorLaborMutation,
  useDeleteCannedJobMotorLaborMutation,
  useCreateCannedJobCustomPartMutation,
  useUpdateCannedJobCustomPartMutation,
  useDeleteCannedJobCustomPartMutation,
  useCreateCannedJobPartLookupMutation,
  useUpdateCannedJobPartLookupMutation,
  useDeleteCannedJobPartLookupMutation,
  useCreateCannedJobToInventoryPartMutation,
  useUpdateCannedJobToInventoryPartMutation,
  useDeleteCannedJobToInventoryPartMutation,
  useCreateCannedJobMotorFluidsMutation,
  useUpdateCannedJobMotorFluidsMutation,
  useDeleteCannedJobMotorFluidsMutation,
  useCreateCannedJobCustomFeeMutation,
  useUpdateCannedJobCustomFeeMutation,
  useDeleteCannedJobCustomFeeMutation,
  useLazyGetCannedJobMotorFluidsQuery,
  useCopyCannedJobMutation,
  useListCannedPartsQuery,
  useGetCannedJobExpandedByApiKeyQuery,
  useCreateCannedJobToInventoryProductMutation,
  useUpdateCannedJobToInventoryProductMutation,
  useDeleteCannedJobToInventoryProductMutation,
  useCreateCannedJobRelatedMutation,
  useUpdateCannedJobRelatedMutation,
  useDeleteCannedJobRelatedMutation,
  useGetCannedJobRelatedQuery,
  useGetCannedJobRelatedToParentQuery,
  useCreateServiceFromCannedJobMutation,
  useLazyGetCannedJobMotorLaborQuery,
  useGetCannedJobMotorLaborQuery,
  useGetCannedJobMotorFluidsQuery
} = cannedJobApi
