import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { APPOINTMENT_COLORS_DARK } from '../../components/Appointments/AppointmentColorSelect'
import useAccessTokenAndShopId from '../../hooks/useAccessTokenAndShopId/useAccessTokenAndShopId'
import { QueryParamNames } from '../../models/QueryParamNames'
import { SerializableAppointmentEntry } from '../../models/SerializableSdkTypes/SerializableAppointmentEntry'
import {
  useGetCustomerDetailQuery,
  useGetCustomersQuery,
  useGetLatestAppointmentsQuery,
  useGetOtherPeopleProfilesQuery,
  useGetRepairOrdersQuery,
  useGetShopResourcesQuery,
  useGetSingleAppointmentQuery,
  useGetVehicleDetailQuery,
  useGetVehiclesQuery
} from '../../redux/api'
import {
  CustomerEntry,
  RepairOrderExpanded,
  RepairOrderStatusEntry,
  ResourceEntry,
  UserProfile,
  VehicleEntry
} from '../../sdk'
import { dedupArray } from '../../utils/dedupArray/dedupArray'
import { loggingAssert } from '../../utils/handleError/loggingAssert'
import { AppContext } from '../AppProvider/AppProvider'
export const DUMMY_NEW_APPOINTMENT: SerializableAppointmentEntry = {
  id: 'new',
  info: { startDateTime: [0, 0, 0, 0, 0] }
}

interface NewAppointmentContextValues {
  date: Date
  setDate: (date: Date) => void
  setStartDate: (date: Date | undefined) => void
  setEndDate: (date: Date | undefined) => void
  selectedResource: string[]
  setSelectedResource: (selectedResource: string[]) => void
  openPanel: boolean
  setOpenPanel: (data: boolean) => void
  appointments: SerializableAppointmentEntry[] | undefined
  shopResources: ResourceEntry[] | undefined
  customersMap: { [key: string]: CustomerEntry }
  vehiclesMap: { [key: string]: VehicleEntry }
  apptToRepairOrdersMap: { [key: string]: RepairOrderExpanded }
  isLoading: boolean
  statuses: RepairOrderStatusEntry[] | undefined
  roToTechniciansMap: { [key: string]: UserProfile[] }
  selectedAppointment: SerializableAppointmentEntry | undefined
  setSelectedAppointment: (
    appt: SerializableAppointmentEntry | undefined
  ) => void
  selectedCustomer: CustomerEntry | undefined
  setSelectedCustomer: (c: CustomerEntry | undefined) => void
  selectedVehicle: VehicleEntry | undefined
  setSelectedVehicle: (v: CustomerEntry | undefined) => void
  selectedColor: string
  setSelectedColor: (c: string) => void
}

export const NewAppointmentContext = createContext<NewAppointmentContextValues>(
  {
    date: new Date(),
    setDate: () => {},
    setStartDate: () => {},
    setEndDate: () => {},
    selectedResource: [],
    setSelectedResource: () => {},
    openPanel: false,
    setOpenPanel: () => {},
    appointments: undefined,
    shopResources: undefined,
    customersMap: {},
    vehiclesMap: {},
    apptToRepairOrdersMap: {},
    isLoading: false,
    statuses: undefined,
    roToTechniciansMap: {},
    selectedAppointment: undefined,
    setSelectedAppointment: () => {},
    selectedCustomer: undefined,
    setSelectedCustomer: () => {},
    selectedVehicle: undefined,
    setSelectedVehicle: () => {},
    selectedColor: '',
    setSelectedColor: () => {}
  }
)

function getNextBusinessHour(
  curTime: Date,
  start: Date | undefined,
  end: Date | undefined
) {
  if (!start || !end) {
    return curTime
  }
  if (
    curTime.getHours() < start.getHours() ||
    (curTime.getHours() === start.getHours() &&
      curTime.getMinutes() < start.getMinutes())
  ) {
    curTime.setHours(start.getHours(), start.getMinutes(), 0)
  } else if (
    curTime.getHours() >= end.getHours() ||
    (curTime.getHours() === end.getHours() &&
      curTime.getMinutes() > end.getMinutes())
  ) {
    curTime.setHours(start.getHours(), start.getMinutes(), 0)
    curTime.setDate(curTime.getDate() + 1)
  }
  return curTime
}

interface Props {
  children?: React.ReactNode
}

const NewAppointmentProvider: FC<Props> = ({ children }) => {
  const { shopProfile } = useContext(AppContext)
  const { id: apptId } = useParams()
  const [searchParams] = useSearchParams()
  const paramCustomerId = searchParams.get(QueryParamNames.CUSTOMER_ID)
  const paramVehicleId = searchParams.get(QueryParamNames.VEHICLE_ID)
  const { accessToken, shopId } = useAccessTokenAndShopId()
  const [date, setDate] = useState(
    getNextBusinessHour(
      new Date(),
      shopProfile?.info!.calendarStartTime,
      shopProfile?.info!.calendarEndTime
    )
  )
  const [startDate, setStartDate] = useState<Date | undefined>(
    new Date(Date.now() - 24 * 60 * 60 * 1000 * 7)
  )
  const [endDate, setEndDate] = useState<Date | undefined>(
    new Date(Date.now() + 24 * 60 * 60 * 1000 * 7)
  )
  const [selectedResource, setSelectedResource] = useState<string[]>([])
  const [openPanel, setOpenPanel] = useState(false)
  const [selectedAppointment, setSelectedAppointment] = useState<
    SerializableAppointmentEntry | undefined
  >()
  const [selectedCustomer, setSelectedCustomer] = useState<
    CustomerEntry | undefined
  >()
  const [selectedVehicle, setSelectedVehicle] = useState<
    VehicleEntry | undefined
  >()
  const [selectedColor, setSelectedColor] = useState(APPOINTMENT_COLORS_DARK[0])

  const { data: appointmentsFromParams, isLoading: loadingCurAppt } =
    useGetSingleAppointmentQuery(
      {
        appointment: [apptId!],
        authorization: accessToken,
        shop: shopId
      },
      { skip: !apptId || apptId === 'new', refetchOnMountOrArgChange: true }
    )
  const { data: customersFromParams, isLoading: loadingCurCustomer } =
    useGetCustomerDetailQuery(
      {
        authorization: accessToken,
        shop: shopId,
        customer: [paramCustomerId!]
      },
      { skip: !paramCustomerId }
    )
  const { data: vehiclesFromParams, isLoading: loadingCurVehicle } =
    useGetVehicleDetailQuery(
      {
        authorization: accessToken,
        shop: shopId,
        vehicle: [paramVehicleId!]
      },
      { skip: !paramVehicleId }
    )

  useEffect(() => {
    if (appointmentsFromParams && appointmentsFromParams[0]) {
      setSelectedAppointment(
        appointmentsFromParams && appointmentsFromParams[0]
      )
    } else if (apptId && apptId === 'new') {
      setSelectedAppointment(DUMMY_NEW_APPOINTMENT)
    }
  }, [appointmentsFromParams, apptId])
  useEffect(() => {
    if (customersFromParams && customersFromParams[0]) {
      setSelectedCustomer(customersFromParams[0])
    }
  }, [customersFromParams])

  useEffect(() => {
    if (vehiclesFromParams && vehiclesFromParams[0]) {
      setSelectedVehicle(vehiclesFromParams[0])
    }
  }, [vehiclesFromParams])

  useEffect(() => {
    if (selectedAppointment) {
      setOpenPanel(true)
    } else setOpenPanel(false)
  }, [selectedAppointment])

  const { data: shopResources, isLoading: loadingResource } =
    useGetShopResourcesQuery({
      authorization: accessToken,
      shop: shopId
    })
  const { data: appointments, isLoading: loadingAppt } =
    useGetLatestAppointmentsQuery(
      {
        authorization: accessToken,
        shop: loggingAssert('ShopId not set', shopId),
        startDateInclusive: startDate
          ? startDate.toISOString().slice(0, -2)
          : undefined,
        endDateExclusive: endDate
          ? endDate.toISOString().slice(0, -2)
          : undefined
      },
      { refetchOnMountOrArgChange: true }
    )
  const existingCustomers = useMemo(
    () =>
      appointments &&
      new Set(appointments.map((appointment) => appointment.info.customer!)),
    [appointments]
  )

  const { data: customers, isLoading: loadingCustomer } = useGetCustomersQuery(
    {
      authorization: accessToken,
      shop: shopId,
      customer: existingCustomers ? Array.from(existingCustomers) : []
    },
    {
      skip: !existingCustomers || existingCustomers.size === 0
    }
  )
  const customersMap = useMemo(() => {
    const map: { [key: string]: CustomerEntry } = {}
    customers?.forEach((c) => (map[c.id!] = c))
    return map
  }, [customers])

  const existingVehicles = useMemo(
    () =>
      appointments &&
      new Set(
        appointments
          .filter((appointment) => appointment.info.vehicle)
          .map((appointment) => appointment.info.vehicle!)
      ),
    [appointments]
  )

  const { data: vehicles, isLoading: loadingVehicle } = useGetVehiclesQuery(
    {
      authorization: accessToken,
      shop: shopId,
      vehicle: existingVehicles ? Array.from(existingVehicles) : []
    },
    {
      skip: !existingVehicles || existingVehicles.size === 0
    }
  )
  const vehiclesMap = useMemo(() => {
    const map: { [key: string]: VehicleEntry } = {}
    vehicles?.forEach((c) => (map[c.id!] = c))
    return map
  }, [vehicles])

  const { data: repairOrders, isLoading: loadingRepairOrder } =
    useGetRepairOrdersQuery(
      {
        authorization: accessToken,
        shop: shopId,
        appointment: (appointments || []).map((a) => a.id!)
      },
      {
        skip: !appointments || appointments.length === 0
      }
    )
  const apptToRepairOrdersMap = useMemo(() => {
    const map: { [key: string]: RepairOrderExpanded } = {}
    repairOrders?.forEach((c) => (map[c.entry!.info!.appointment!] = c))
    return map
  }, [repairOrders])
  const { repairOrderStatuses } = useContext(AppContext)

  const techIds = useMemo(
    () =>
      repairOrders ? dedupArray(repairOrders.flatMap((ro) => ro.techs!)) : [],
    [repairOrders]
  )

  const { data: technicians, isLoading: loadingTechnicians } =
    useGetOtherPeopleProfilesQuery(
      {
        authorization: accessToken,
        user: techIds
      },
      {
        skip: techIds.length === 0
      }
    )

  const roToTechniciansMap = useMemo(() => {
    const map: { [key: string]: UserProfile[] } = {}
    if (!technicians) {
      return map
    }
    repairOrders?.forEach((ro) => {
      const foundTechs = ro
        .techs!.map((id) => technicians.find((t) => t.id === id))
        .filter((tech) => tech) as UserProfile[]
      if (foundTechs.length > 0) {
        map[ro.entry!.id!] = foundTechs
      }
    })
    return map
  }, [repairOrders, technicians])

  const isLoading = useMemo(
    () =>
      loadingAppt ||
      loadingCustomer ||
      loadingVehicle ||
      loadingResource ||
      loadingRepairOrder ||
      loadingTechnicians ||
      loadingCurAppt ||
      loadingCurCustomer ||
      loadingCurVehicle,
    [
      loadingAppt,
      loadingCustomer,
      loadingVehicle,
      loadingResource,
      loadingRepairOrder,
      loadingTechnicians,
      loadingCurAppt,
      loadingCurCustomer,
      loadingCurVehicle
    ]
  )
  return (
    <NewAppointmentContext.Provider
      value={{
        date,
        setDate,
        setStartDate,
        setEndDate,
        selectedResource,
        setSelectedResource,
        openPanel,
        setOpenPanel,
        appointments,
        shopResources,
        customersMap,
        vehiclesMap,
        apptToRepairOrdersMap,
        isLoading,
        statuses: repairOrderStatuses,
        roToTechniciansMap,
        selectedAppointment,
        setSelectedAppointment,
        selectedCustomer,
        setSelectedCustomer,
        selectedVehicle,
        setSelectedVehicle,
        selectedColor,
        setSelectedColor
      }}>
      {children}
    </NewAppointmentContext.Provider>
  )
}

export default NewAppointmentProvider
