import {
  Client as ConversationsClient,
  Conversation
} from '@twilio/conversations'
import { useCallback } from 'react'

import { sdk } from '../../config'
import useAccessTokenAndShopId from '../../hooks/useAccessTokenAndShopId/useAccessTokenAndShopId'
import { loggingAssert } from '../../utils/handleError/loggingAssert'
import useGetMostRecentConversations from './useGetMostRecentConversations'
import useMessageAddedHandler from './useMessageAddedHandler'

const useInitializeListeners = () => {
  const { accessToken, shopId } = useAccessTokenAndShopId()

  const { addOrUpdateConversation, removeConversation } =
    useGetMostRecentConversations()
  const { onMessageAdded } = useMessageAddedHandler()
  const updateToken = useCallback(
    async (conversationsClient: ConversationsClient) => {
      const newToken = await sdk.getTwilioAccessToken({
        authorization: accessToken,
        body: shopId
      })
      conversationsClient.updateToken(newToken)
    },
    [accessToken, shopId]
  )

  const initializeListeners = useCallback(
    (
      conversationsClient: ConversationsClient,
      setConnectionStatus: React.Dispatch<React.SetStateAction<string>>,
      setSubscribedConversations: React.Dispatch<
        React.SetStateAction<Conversation[]>
      >,
      setConversationUniqueNamesWithUnreadMessages: React.Dispatch<
        React.SetStateAction<string[]>
      >,
      newMessageReceived: () => void
    ) => {
      conversationsClient.on('connectionStateChanged', (newState) => {
        setConnectionStatus(newState.toString())
      })
      conversationsClient.on('conversationAdded', (conversation) => {
        addOrUpdateConversation(
          conversation,
          setSubscribedConversations,
          setConversationUniqueNamesWithUnreadMessages
        )
      })
      conversationsClient.on('conversationRemoved', (conversation) => {
        removeConversation(
          conversation,
          setSubscribedConversations,
          setConversationUniqueNamesWithUnreadMessages
        )
      })
      conversationsClient.on('conversationUpdated', (conversation) => {
        addOrUpdateConversation(
          conversation.conversation,
          setSubscribedConversations,
          setConversationUniqueNamesWithUnreadMessages
        )
      })
      conversationsClient.on('tokenExpired', () => {
        updateToken(conversationsClient)
      })
      conversationsClient.on('tokenAboutToExpire', () => {
        updateToken(conversationsClient)
      })
      conversationsClient.on('messageAdded', (message) => {
        onMessageAdded(message)
        newMessageReceived()
      })
      loggingAssert(
        'Too many listeners set',
        conversationsClient.listenerCount('connectionStateChanged') === 1 &&
          conversationsClient.listenerCount('conversationAdded') === 1 &&
          conversationsClient.listenerCount('conversationRemoved') === 1 &&
          conversationsClient.listenerCount('conversationUpdated') === 1 &&
          conversationsClient.listenerCount('tokenExpired') === 1 &&
          conversationsClient.listenerCount('tokenAboutToExpire') === 1 &&
          conversationsClient.listenerCount('messageAdded') === 1
      )
    },
    [onMessageAdded, updateToken, addOrUpdateConversation, removeConversation]
  )
  return { initializeListeners }
}

export default useInitializeListeners
