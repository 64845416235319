export interface CustomerNameFields {
  firstname?: string
  lastname?: string
  companyName?: string
}

export const formatName = (
  customerInformation: CustomerNameFields | undefined
) => {
  if (customerInformation) {
    if (customerInformation?.firstname || customerInformation?.lastname) {
      return (
        customerInformation?.firstname + ' ' + customerInformation?.lastname
      )
    } else if (customerInformation?.companyName) {
      return customerInformation?.companyName
    }
  }
  return 'N/A'
}
