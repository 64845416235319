import React, { createContext, FC, useState } from 'react'

type NotificationContextValues = {
  notice: string
  setNotice: (notice: string) => void
  error: string
  setError: (error: string) => void
}

export const NotificationContext = createContext<NotificationContextValues>({
  notice: '',
  setNotice: () => {},
  error: '',
  setError: () => {}
})

interface Props {
  children?: React.ReactNode
}

export const NotificationProvider: FC<Props> = ({ children }) => {
  const [notice, setNotice] = useState('')
  const [error, setError] = useState('')
  return (
    <NotificationContext.Provider
      value={{ notice, setNotice, error, setError }}>
      {children}
    </NotificationContext.Provider>
  )
}
