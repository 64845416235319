import { useCallback } from 'react'

import { sendBugReport } from '../../utils/handleError/sendBugReport'
import useAccessTokenAndShopId from '../useAccessTokenAndShopId/useAccessTokenAndShopId'

const useRequestNotificationPermission: () => {
  permission: 'notSupported' | 'granted' | 'denied' | 'default'
  askPermission: () => void
} = () => {
  const { userId, shopId } = useAccessTokenAndShopId()
  const permission: 'notSupported' | 'granted' | 'denied' | 'default' =
    'Notification' in window ? Notification.permission : 'notSupported'

  // The API for getting permission is relatively simple, the downside is that the API recently changed from taking a callback to returning a Promise. The problem with this, is that we can't tell what version of the API is implemented by the current browser, so you have to implement both and handle both.

  const askPermission = useCallback(() => {
    if (permission === 'notSupported') {
      sendBugReport(
        'warning',
        'Notification not supported',
        `This browser does not support desktop notification. User id ${userId}. Shop id ${shopId}`
      )
      return
    }
    if (permission === 'granted') {
      return
    }
    return new Promise(function (resolve, reject) {
      const permissionResult = Notification.requestPermission(function (
        result
      ) {
        resolve(result)
      })
      if (permissionResult) {
        permissionResult.then(resolve, reject)
      }
    }).then(function (permissionResult) {
      if (permissionResult !== 'granted') {
        sendBugReport(
          'warning',
          'Notification permission denied',
          `User denied the permission to display notifications. User id ${userId}. Shop id ${shopId}`
        )
      }
    })
  }, [permission, shopId, userId])

  return { permission, askPermission }
}

export default useRequestNotificationPermission
