import React, { createContext, FC, useEffect, useState } from 'react'

type WindowSizeContextValues = {
  width: number
  height: number
}

export const WindowSizeContext = createContext<WindowSizeContextValues>({
  width: window.innerWidth,
  height: window.innerHeight
})

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height
  }
}

interface Props {
  children?: React.ReactNode
}

export const WindowSizeProvider: FC<Props> = ({ children }) => {
  const [windowSizeValues, setWindowSizeValues] =
    useState<WindowSizeContextValues>({
      width: window.innerWidth,
      height: window.innerHeight
    })

  useEffect(() => {
    const handleResize = () => setWindowSizeValues(getWindowDimensions())

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [setWindowSizeValues])

  return (
    <WindowSizeContext.Provider value={windowSizeValues}>
      {children}
    </WindowSizeContext.Provider>
  )
}
