import { Message } from '@twilio/conversations'
import { useCallback, useContext } from 'react'

import { sdk } from '../../config'
import useAccessTokenAndShopId from '../../hooks/useAccessTokenAndShopId/useAccessTokenAndShopId'
import { AuthContext } from '../AuthProvider/AuthProvider'
import {
  MERGED_CUSTOMER_CONVERSATION_UNIQUE_NAME,
  MERGED_REPAIR_ORDERS_CONVERSATION_UNIQUE_NAME
} from './TwilioConversationsProvider'

const useMessageAddedHandler = () => {
  const { currentUser } = useContext(AuthContext)
  const { accessToken } = useAccessTokenAndShopId()

  const onMessageAdded = useCallback(
    async (message: Message) => {
      if (
        message.author === currentUser?.id ||
        typeof message.attributes !== 'object' ||
        !('original_conversation_unique_name' in message.attributes!) ||
        !('original_conversation_friendly_name' in message.attributes)
      ) {
        return
      }
      const originalConversationUniqueName =
        message.attributes.original_conversation_unique_name!.toString()
      if (
        message.conversation.uniqueName ===
          MERGED_CUSTOMER_CONVERSATION_UNIQUE_NAME &&
        message.author?.length !== 36
      ) {
        sdk.joinCustomerChat({
          authorization: accessToken,
          body: originalConversationUniqueName
        })
      }
      if (
        message.conversation.uniqueName ===
        MERGED_REPAIR_ORDERS_CONVERSATION_UNIQUE_NAME
      ) {
        sdk.joinRepairOrder({
          authorization: accessToken,
          body: originalConversationUniqueName
        })
      }
    },
    [accessToken, currentUser?.id]
  )

  return { onMessageAdded }
}

export default useMessageAddedHandler
