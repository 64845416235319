import { sendBugReport } from './sendBugReport'

export function loggingAssert<Type>(message: string, arg?: Type): Type {
  // https://stackoverflow.com/questions/28975896/is-there-a-way-to-check-for-both-null-and-undefined
  // Checks for null, NaN, undefined, empty string, 0, false
  if (arg) {
    return arg
  }

  sendBugReport(
    'error',
    `Frontend assertion failed: ${message}`,
    `Message: ${message}\nStack trace: ${Error().stack}`
  )

  throw new Error(message)
}

export function loggingAssertNotNull<Type>(message: string, arg?: Type): Type {
  // https://stackoverflow.com/questions/28975896/is-there-a-way-to-check-for-both-null-and-undefined
  // Checks for null, NaN, undefined
  if (arg !== null && arg !== undefined) {
    return arg
  }

  sendBugReport(
    'error',
    `Frontend assertion failed: ${message}`,
    `Message: ${message}\nStack trace: ${Error().stack}`
  )

  throw new Error(message)
}
